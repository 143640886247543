
var root;
var count = 1;
var childnode = "";
var lidcount = 1;
var ridcount = 1000;
var condtiontype = ["Select Condition type", "starts-with", "not starts-with", "Equals", "not Equals", "contains", "not contains"];
var lidname = "templateattr";
var ridname = "mappedattr";
var nidname = "node";
var tagidname = "xmltag";
var tagcount = 30000;
var toggledividname = "togglenodediv";
var toggledividcount = 40000;
var ncount = 10000;
var mappedxml = false;
var dropid = "";
var attrid = "";
var parentnodexpath = "";
var mappedclick = 0;
var flagrepeatablenode = false;
var repeatableflag = false;
var repeatablenodename = [];
var repeatablenode = [];
var childnodeflag = false;
var repetablenodexpath = "";
var xmlparentnodediv = "";
var replacexpathforrepeatableflag = false;
var node = [];
var defaultrepeatablenode = [];
var removerepeatablenode = [];
var removerepeatablenodechildflag = false;
var droppeddata = "";
var draggedvalue = "";
var conditiondivcount = 0;
var functionname = "";
var constantflag = false;
var isMappedXmlLoaded = false;
let undomap = new Map();
let redomap = new Map();
let undobackup = new Map();
let redobackup = new Map();
var variabledivid = "";
var dyNamictextidname = "cancat";
var dyNamictextidcount = 50000;
let variablemap = new Map();
var textattrid = 60000;
var closeattrid = 70000;
var iddateFormatList = "";
var isRepeatableNode = false;
var removedRepeatablePath = "";
var removedRepeatablenodename = "";
var xPathRepeatable = "";
var childNodesListForRepetableNode = [];
var childNodesListForRemoveRepetableNode = [];
var isParentNodeRepeatablenodeAdded = false;
var textinputid = "";
var dropdownbtnid = "";
var inputtype = "";
var _rootDivId = "";
var _immediateParentId = "";
var _xmlNodeTreename = "xmlTree";
var _xmlNodeTreeCount = 60000;
var _isAttr = false;
var commentullistid="";
var ignoreattribute=["_repeatablenode","_nodeidentifier","key","name","notes","_addcondition","comment"];

// variablemap.set("OBX_SpecialInstructions", "_function:ConcatNodeAttributes(HL7Message/OBX[./OBX.3/OBX.3.1 = 'SpecialInstructions']#1=1#(./OBX.5/OBX.5.1)#;)");
// variablemap.set("Specimen_Count", "_function:ReturnValue(count(HL7Message/OBX[starts-with(./OBX.3/OBX.3.1,'SPECIMEN_40') or (./OBX.3/OBX.3.2 = 'Source')]) = '1'#HL7Message/OBX[starts-with(./OBX.3/OBX.3.1,'SPECIMEN_40') or (./OBX.3/OBX.3.2 = 'Source')]#./OBX.5/OBX.5.1");
// variablemap.set("ICD_Codes", "_function:ConcatNodeAttributes(HL7Message/DG1#1=1#./DG1.3/DG1.3.1#;)");
// variablemap.set("OBR_SpecialInstructions", "_function:ConcatNodeAttributes(HL7Message/NTE[./@parentId= $OBRid]#1=1#./NTE.3/NTE.3.1#;)");
// variablemap.set("OBRid", "HL7Message/OBR/@id");

var stringdivid = "";
var isModalOpen = false;

var repetablenodeAdded = "";

var stringoperationdivid = "";

var insertedvariablelist = [];

var dropdownbtnforoperationid = "";

//closing mymodal


//To the attribute value in mymodal
function displayvalue(element, event) {

  event.stopPropagation();

  removedDisableAttr();


  document.getElementById("idDivExpression").style.display = "block";
  document.getElementById("textComment").value="";

  var attrInnerHTML = "";
  var sovalue = "";
  var constantvalue = "";

  isModalOpen = true;
  constantflag = false;

  stringdivid = "";
  textinputid = "";
  dropdownbtnid = "";
  dropdownbtnforoperationid = "";
  dropdownidsp = "";

  var parentid=element.parentNode.id;

  var isnode = document.getElementById(parentid).getAttribute("node");

  if (isnode) {
    attrInnerHTML = document.getElementById(parentid).childNodes[1].innerHTML;
  } else {
    attrInnerHTML = document.getElementById(parentid).childNodes[0].innerHTML;
  }

  //Getting attribute value
  var attrvalue = attrInnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
  var stringoperationid = document.getElementById(parentid).getAttribute("stringopdivid");
  var _isConditionAdded =$("#"+parentid).attr("addedcondition");

  var tag = document.getElementById(parentid).getAttribute("tag");

  if (!(stringoperationid == null)) {
    sovalue = document.getElementById(stringoperationid).innerHTML;
    sovalue = sovalue.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
  }


  //setting the everytime constantflag to false when click on constant then on we have to enable flag

  //setting the attributeid as globle value
  if (attrid != "") {
    clearMappedXmlOperation();
  }

  document.getElementById("idStringParameter1").readOnly=true;
  document.getElementById("idStringParameter2").readOnly=true;
  document.getElementById("idattrInput").value = tag;
  attrid =parentid;
  var operation="";
  //checking attribute value has the function or not
  if (attrvalue.toLowerCase().includes("concat") && !attrvalue.toLowerCase().includes("concatnode")) {
   
    operation="Concat";
    AddConcatAttr();
  } else if (attrvalue.toLowerCase().includes("getmapping")) {
    
    //Calling createdivformappingvalue function
    operation="Get Mapping";
    createdivformappingvalue("", "");
  } else if (attrvalue.toLowerCase().includes("getpdf")) {
    //Calling createdivformappingvalue function 
    AddPdf();
  } 
  else if (attrvalue.toLowerCase().includes("getsplit")) {
   
    operation="Get Split";
    AddGetSplit();
  } else if (attrvalue.toLowerCase().includes("getalphanumeric")) {
    operation="Get Alphanumeric"
    AddGetAlphanumeric();
  } else if (attrvalue.toLowerCase().includes("getconvertdatetime")) {
    operation="GetConvert Date&Time "
    AddDateFormatAttr();

  } else if (attrvalue.toLowerCase().includes("getcurrenttimestamp")) {
    
    operation="GetCurrent Date&Time "
    AddCurrentDateFormatAttr();
  } else if (attrvalue.toLowerCase().includes("concatnode")) {
    
    operation="Concat NodeAttributes";
    AddConcatNodeAttributes();
  } else if (attrvalue.toLowerCase().includes("count")) {
    
    operation="Count";
    AddCount();
  } else if (attrvalue.toLowerCase().includes("getdownloadfilefromcore")) {
    
    operation="Download File"
    AddDownloadFile();
  } else if (attrvalue.toLowerCase().includes("getembeddedcontent")) {
    
    operation="Embedded Content"
    AddEmbeddedContent();
  } else if (attrvalue.toLowerCase().includes("string-length")) {
    
    operation="Length";
    AddLength();
  } else if (attrvalue.toLowerCase().includes("getheader")) {
    
    operation="Get Header";
    AddHeader();
  } else if (attrvalue.startsWith("'") && attrvalue.endsWith("'")) {
    
    operation="Constant";
    AddStringConstant();
  } else if (attrvalue.toLowerCase().includes("getremoveextraspaces")) {
    
    operation="Remove Extra Spaces ";
    AddRemoveExtraSpace();
  } else if (attrvalue.toLowerCase().includes("getcurrentdatetimeroundoff")) {
    
    operation="GetCurrent Date&Time RoundOff";
    AddCurrentDateTimeRoundOff();
  } else if (attrvalue.toLowerCase().includes("getconvertdatetimeroundoff")) {
    
    operation="GetConvert Date&Time RoundOff";
    AddConvertDateTimeRoundOff();
  } else if (attrvalue.toLowerCase().includes("getreplace")) {
    
    operation="Get Replace";
    AddReplace();
  } else if (attrvalue.toLowerCase().includes("getmatchingconditionvalue")) {
    
    operation="Matching Condition Value";
    AddTernaryOpAttr();
  }else if(attrvalue.startsWith("$")){
    
    operation="Variable";
    AddVariableOP();
  } else {
    //checking the whether it node or attribute one
    if (isnode) {
      //checking the node has attribute or not

      document.getElementById("idDivRepeatable").style.display = "block";
      document.getElementById("idDivAddCondition").style.display = "block";
      document.getElementById("idSnippet").style.display = "block";
      document.getElementById("idVariableNode").style.display="block";
      document.getElementById("conditiondiv").classList.remove("d-none");
      document.getElementById("idCustomLabel").innerHTML = "Add Condition";
      var isRepeatableNode = document.getElementById(attrid).getAttribute("repeatablenode");
      
      
      // document.getElementById("idaddAttributeToNode").style.display="block";

      // appendText(attrvalue.replace("&lt;", ""), "inputText", "attributevalue", "");
      if (isRepeatableNode) {
        document.getElementById("idrepeatable").checked = true;
      }
      if (_isConditionAdded) {
        var addConditionDivid = document.getElementById(attrid).getAttribute("_addconditionid");
        var addConditionValue = "";
        document.getElementById("idConditionExpression").style.display = "block";
        if (addConditionDivid != null && addConditionDivid != "" && addConditionDivid != undefined) {
          var addConditionValue = document.getElementById(addConditionDivid).childNodes[0].innerHTML;
          document.getElementById("idcondition").checked = true;
          document.getElementById("idConditionExpression1").value = addConditionValue.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
        }
      }
    } else {

      document.getElementById("conditiondiv").classList.add("d-none");
      if (attrvalue != "") {
        if (attrvalue.includes("@Constant")) {
          constantvalue = attrvalue.split(":");
          constantflag = true;
         // appendText(constantvalue[1], "inputText", "attributevalue", "");
        } else {
          // appendText(attrvalue.replace("&lt;", ""), "inputText", "attributevalue", "");
          
        }
      } else {
        document.getElementById("inputText").firstChild.style.display = "block";
        document.getElementById("inputText").firstChild.focus();
      }
    }
  }
  //Adding xpath for mymodal
  // document.getElementById("xpath").innerHTML = tag.replace("@", "") + "=";
  checkCommentExistOrNot();
  if(commentullistid==""){
    document.getElementById("comment").style.display="none";
  }
  document.getElementById("idMappingOperationdetails").innerHTML=operation;
  document.getElementById("mappedattributevalue").innerHTML = tag.replace("@", "") + "=" + attrvalue.replace("&lt;", "");
}
//To get outputformat and inputformat  list id
//Validation for addmapping
function addmappingvalidate(textvalue) {
  //checking the constant value
  if (constantflag) {

    if (textvalue == "") {
      displayalertmsg("Please enter the constant value");
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
//Creating mapping value for the attribute
function addmapping() {
  var concatattribute = document.getElementById(attrid).getAttribute("concatattr");
  var attrvalue = document.getElementById(attrid).innerHTML;
  if (concatattribute) {
    clearstringopeationvalue("concatoperation");
    document.getElementById(attrid).removeAttribute("concatattr");
  }
  var attributevaluelist = document.getElementsByClassName("attributevalue");
  var tag = document.getElementById(attrid).getAttribute("tag");
  var textvalue = "";
  if (attributevaluelist.length > 0) {
    textvalue = attributevaluelist[0].innerText;
  }
  var getxpath = tag.split("=")[0];
  var nodes = getxpath.split("/");
  var constantvalue = "";
  dropdownbtnid = "";
  var validate = addmappingvalidate(textvalue);
  //Checking the attribute already has the GetMappingValue
  if (!textvalue.toLowerCase().includes("getmappingvalue")) {
    //Checking the node length has to greater the 1 and validation has to be true
    if (nodes.length > 1 && validate == true) {
      //Checking the textvalue is empty of not
      if (textvalue == "") {
        createdivformappingvalue(nodes[nodes.length - 1], getxpath)
      } else {
        if (constantflag) {
          createdivformappingvalue(nodes[nodes.length - 1], constantvalue + textvalue);
        } else {
          createdivformappingvalue(nodes[nodes.length - 1], getxpath);
        }

      }

    }
  }
}

function addConstant() {
  var value = document.getElementById(attrid).innerHTML;
  var concatattribute = document.getElementById(attrid).getAttribute("concatattr");
  var mappingattribute = document.getElementById(attrid).getAttribute("mapping");
  var operation = "";
  var clsname = "";
  if (concatattribute) {
    operation = "concatoperation";
    clsname = "concat";
  } else if (mappingattribute) {
    operation = "GetMapping";
    clsname = "mapping";
  } else {
    clsname = "attributevalue";
  }

  if (stringdivid != "") {

    document.getElementById("dropdownlist").style.display = "none";

    document.getElementById(textinputid).parentNode.style.display = 'block';
    if (document.getElementById(textinputid).parentNode.nextSibling) {
      document.getElementById(textinputid).parentNode.nextSibling.remove();
    }

    document.getElementById(dropdownbtnid).style.height = "50px";

    document.getElementById(textinputid).value = "";
    document.getElementById(textinputid).focus();
    document.getElementById(textinputid).setAttribute("onkeydown", "AddInputValueToTextOnkeyDown(event,this.id,this.parentNode.parentNode.id,'" + clsname + "','" + operation + "' )");
    document.getElementById(textinputid).setAttribute("onfocusout", "AddInputValueToTextOnFocusOut(this.id,this.parentNode.parentNode.id,'" + clsname + "','" + operation + "' )");
    document.getElementById(textinputid).parentNode.setAttribute("isConstantAdded", "true");
  }

  constantflag = true;
  var attrvalue = value.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
  if (attrvalue.toLowerCase().includes("@constant")) {
    document.getElementById("value").value = "";
  }
}

function Repeatable() {

  var value = document.getElementById(attrid).innerHTML;
  var attrvalue = value.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
  var tag = document.getElementById(attrid).getAttribute("tag");
  document.getElementById("xpath").innerHTML = tag.split("=")[0] + "/@_RepeatableNode" + "=";

}

function removetextElement(nodearray) {
  for (var i = 0; i < nodearray.length; i++) {
    if (nodearray[i].nodeName == "#text") {
      nodearray[i].remove();
    }
  }
  return nodearray;
}
var xmlString;

function loadmappedxml(mappedxmldata) {

  document.getElementById("idMappedXmlDiv").innerHTML="";
  clearglobalvalues();
  var xmlDocument = "";
    xmlDocument = (new DOMParser()).parseFromString(mappedxmldata, 'text/xml');
    mappedxml = true;

    getxml(xmlDocument, mappedxml);
    isMappedXmlLoaded = true;
    repeatablenode = [];
    removerepeatablenode = [];
    addVariableTodropDown();
}

var xmlString = "";

function loadxml(Template, MappedXml, type) {

  document.getElementById("idTemplateXmlDiv").innerHTML="";
  var templatexmldata = Template;
  var mappedxmldata = MappedXml;
  inputtype = type;
  var domParser = new DOMParser();

  clearglobalvalues();
  var xmlDocument = "";
    xmlDocument = domParser.parseFromString(templatexmldata, "text/xml");
    mappedxml = false;
    getxml(xmlDocument, mappedxml);

    loadmappedxml(mappedxmldata);
}

function clearglobalvalues() {

  // caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
  document.getElementById("idDivExpression").style.display="none";
  document.getElementById("idattrInput").value="";
  undomap = new Map();
  redomap = new Map();
  undobackup = new Map();
  redobackup = new Map();
  insertedvariablelist = [];
  stringdivid = "";
  defaultrepeatablenode = [];
  repeatablenodename = [];
  isRepeatableNode = false;
  dropid = "";
  attrid = "";
  repetablenodexpath = "";
  dropdownbtnid = "";
  textinputid = "";
  variabledivid = "";
  dropdownidsp = "";
  _immediateParentId = "";
  _rootDivId = "";
  _isNodeContainsAttribute = false; 

}

function getxml(xmlDoc, mappedxml) {

  _isNodeContainsAttribute = false;
  childnode = "";
  var _parentDivId = "";
  var _parentxpath = "";
  var xmlnodes = xmlDoc.childNodes[0];
  root = xmlDoc.childNodes[0].nodeName;

  var isemptyflag = false;
  var snippetId = "";
  isemptyflag = checkisemptyornot(attrid);
  var isSnippet = "";
  var nodeType = "";
  if (!isemptyflag) {
    isSnippet = document.getElementById(attrid).getAttribute("addedSnippet");
    nodeType = document.getElementById(attrid).getAttribute("nodeType");
  }
  var createlistNode = () => {
    if (nodeType == "childNode") {
      _parentxpath = document.getElementById(attrid).getAttribute("tag");
      var ulListId = checkUlexistOrNot(attrid);
      _parentDivId = creatlistNode(xmlnodes.nodeName, ulListId, _parentxpath + "/" + xmlnodes.nodeName, "false");
    } else {
      _parentxpath = document.getElementById(attrid).parentNode.parentNode.getAttribute("tag");
      _parentDivId = creatlistNode(xmlnodes.nodeName, attrid, _parentxpath + "/" + xmlnodes.nodeName, "false");
    }
  }
  if (xmlnodes.attributes.length == 0) {
    childnodeflag = true;
    isemptyflag = checkisemptyornot(isSnippet);
    if (!isemptyflag) {
      createlistNode();
    } else if (mappedxml) {
      _parentDivId = creatlistNode(xmlnodes.nodeName, "idMappedXmlDiv", xmlnodes.nodeName, "false");
    } else {
      _parentDivId = creatlistNode(xmlnodes.nodeName, "idTemplateXmlDiv", xmlnodes.nodeName, "false");
    }
    if(xmlnodes.childNodes.length > 0){
      _parentDivId = createrootNode(_parentDivId, "childnodes");
    }
    else{
      _parentDivId = createrootNode(_parentDivId, "void");
    }
    _rootDivId = _parentDivId;
  }

  if (xmlnodes.attributes.length > 0) {
    childnodeflag = true;

    isemptyflag = checkisemptyornot(isSnippet);
    if (!isemptyflag) {
      createlistNode();
    } else if (mappedxml) {
      _parentDivId = creatlistNode(xmlnodes.nodeName, "idMappedXmlDiv", xmlnodes.nodeName, "false");
    } else {
      _parentDivId = creatlistNode(xmlnodes.nodeName, "idTemplateXmlDiv", xmlnodes.nodeName, "false");
    }
    if (xmlnodes.childNodes.length > 0) {
      _parentDivId = createrootNode(_parentDivId, "childnodeswithattr");
    } else {
      _parentDivId = createrootNode(_parentDivId, "attributes");
    }

    _rootDivId = _parentDivId;
    _isNodeContainsAttribute = true;
    getattributes(removetextElement(xmlnodes.attributes), xmlnodes.nodeName, childnodeflag, mappedxml, _parentDivId);
  }

  if (xmlnodes.childNodes.length > 0) {
    childnodeflag = true;
    isemptyflag = checkisemptyornot(isSnippet);
    if (!isemptyflag) {
      _parentxpath = document.getElementById(attrid).parentNode.parentNode.getAttribute("tag");
      getchildnodes(removetextElement(xmlnodes.childNodes), _parentxpath + "/" + xmlnodes.nodeName, mappedxml, _rootDivId, root);
    } else {
      getchildnodes(removetextElement(xmlnodes.childNodes), xmlnodes.nodeName, mappedxml, _rootDivId, root);
    }

  }
}
var _isNode = false;

function getchildnodes(xmlchildnodelist, nodename, mappedxml, _immediateparentDivId, _parentNodeName) {
  var _parentDivId = "";
  _isAttr = false;
  for (var i = 0; i < xmlchildnodelist.length; i++) {

    _isNodeContainsAttribute = false;
    _isNode = false;

    if (xmlchildnodelist[i].nodeName != "#text" && xmlchildnodelist[i].childNodes.length == 0) {
      childnodeflag = false;
    } else {
      childnodeflag = true;
    }
    if (xmlchildnodelist[i].nodeName != "#text" && xmlchildnodelist[i].attributes.length > 0) {
      _isNodeContainsAttribute = true;
    } else {
      _isNodeContainsAttribute = false;
    }
    if (xmlchildnodelist[i].nodeName != "#text" && xmlchildnodelist[i].attributes.length == 0 && xmlchildnodelist[i].childNodes.length == 0) {
      _isNode = true;
    }

    if (nodename == "") {
      childnode = "/" + xmlchildnodelist[i].nodeName;
    } else {
      childnode = nodename + "/" + xmlchildnodelist[i].nodeName;
    }

    if (xmlchildnodelist[i].parentNode.nodeName == root) {
      _parentDivId = creatlistNode(xmlchildnodelist[i].nodeName, _rootDivId, childnode, "false");
      _immediateParentId = _parentDivId;
    } else if (xmlchildnodelist[i].parentNode.nodeName == _parentNodeName) {
      _parentDivId = creatlistNode(xmlchildnodelist[i].nodeName, _immediateparentDivId, childnode, "false");
      _immediateParentId = _parentDivId;
    }


    if (xmlchildnodelist[i].nodeName != "#text" && xmlchildnodelist[i].attributes.length > 0) {
      if (xmlchildnodelist[i].childNodes.length > 0) {
        _parentDivId = createrootNode(_parentDivId, "childnodeswithattr");
      } else {
        _parentDivId = createrootNode(_parentDivId, "attributes");
      }

      if (xmlchildnodelist[i].parentNode.nodeName == root) {
        _immediateParentId = _parentDivId;
      }
      _isNodeContainsAttribute = false;
      getattributes(removetextElement(xmlchildnodelist[i].attributes), xmlchildnodelist[i].nodeName, childnodeflag, mappedxml, _parentDivId);
      _isAttr = false;
    }

    if (xmlchildnodelist[i].nodeName != "#text" && xmlchildnodelist[i].childNodes.length > 0 && xmlchildnodelist[i].attributes.length == 0) {
     
      if(mappedxml){
        _parentDivId = createrootNode(_parentDivId, "childnodes");
        var parentListId=document.getElementById(_parentDivId).parentNode.getAttribute("id");
        if((xmlchildnodelist[i].nodeName!="Comment" &&  xmlchildnodelist[i].parentNode.nodeName!="Comment")){
          createAttribute(CreateGuid(), "_NodeIdentifier",parentListId );
        }
        else{
          commentullistid=_parentDivId;
        }
        
      }
      else{
        _parentDivId = createrootNode(_parentDivId, "childnodes");
      }
      if (xmlchildnodelist[i].parentNode.nodeName == root) {
        _immediateParentId = _parentDivId;
      }
    }

    if (xmlchildnodelist[i].nodeName != "#text" && xmlchildnodelist[i].attributes.length == 0 && xmlchildnodelist[i].childNodes.length == 0) {
      _parentDivId = createrootNode(_parentDivId, "void");
      if (xmlchildnodelist[i].parentNode.nodeName == root) {
        _immediateParentId = _parentDivId;
      }
      if(xmlchildnodelist[i].nodeName=="Comment"){
        commentullistid=_parentDivId;
      }
    }

    if (xmlchildnodelist[i].nodeName != "#text" && xmlchildnodelist[i].childNodes.length > 0) {
      childnodeflag = true;
      parentnodexpath = nodename + "/" + xmlchildnodelist[i].nodeName;
      getchildnodes(removetextElement(xmlchildnodelist[i].childNodes), parentnodexpath, mappedxml, _parentDivId, xmlchildnodelist[i].nodeName);
      if(xmlchildnodelist[i].nodeName.toLowerCase()=="variables" && mappedxml && xmlchildnodelist[i].nextSibling.nodeName.toLowerCase()!="comment"){
        _parentDivId = creatlistNode("Comment", _immediateparentDivId, nodename + "/Comment" , "false");
        _parentDivId = createrootNode(_parentDivId, "void");
        commentullistid=_parentDivId;
      if (xmlchildnodelist[i].parentNode.nodeName == root) {
        _immediateParentId = _parentDivId;
      }
      }
    }
  }
}


var _isNodeContainsAttribute = false;
var isContainsNodeIdentifier = false;

function getattributes(xmlattributenodelist, node, childnodeflag, mappedxml, _parentDivId) {

  var attrxpath = "";
  var attribute = "";
  var attrvalue = "";
  //   _idlistforAttribute=creatlistNode("Attributes", _parentDivId);
  //   _idlistforAttribute=createrootNode(_idlistforAttribute);
  for (var j = 0; j < xmlattributenodelist.length; j++) {
    isContainsNodeIdentifier = false;

    _isAttr = true;
    //checking the attribute has root level xpath
    if (childnode != "") {
      attrxpath = childnode + "/@" + xmlattributenodelist[j].nodeName;
    }

    //checking for attribute has root as xpath
    else {
      attrxpath = root + "/@" + xmlattributenodelist[j].nodeName;
    }
    if (node.toLowerCase() == "variable") {
      if (variablemap.size > 0) {
        if (!(variablemap.has(xmlattributenodelist["name"].nodeValue))) {
          variablemap.set(xmlattributenodelist["name"].nodeValue, xmlattributenodelist["value"].nodeValue);
        }
      } else {
        variablemap.set(xmlattributenodelist["name"].nodeValue, xmlattributenodelist["value"].nodeValue);
      }
    }

      if(mappedxml && j==xmlattributenodelist.length-1 && node!="Key"){
        if (xmlattributenodelist["_NodeIdentifier"]) {
          isContainsNodeIdentifier = false;
        } else {
          isContainsNodeIdentifier = true;
        }
      }
      
    attrvalue = xmlattributenodelist[j].value;
    if (attrvalue != null && attrvalue != "" && attrvalue != undefined) {
      attribute = xmlattributenodelist[j].nodeName + "=" + '"' + attrvalue + '"';
    } else {
      attribute = xmlattributenodelist[j].nodeName + "=" + '"' + '"';
    }
    if (j == xmlattributenodelist.length - 1) {
      creatlistNode(attribute, _parentDivId, attrxpath.replace("mappingData/", ""), "true");
    } else {
      creatlistNode(attribute, _parentDivId, attrxpath.replace("mappingData/", ""), "false");
    }
  }
  isContainsNodeIdentifier = false;
}

function allowDrop(ev) {
  ev.preventDefault();
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function drop(ev) {

  ev.preventDefault();

  var IsdraggedElementConatainsNode = "";
  var draggedDivId = ev.dataTransfer.getData("text");


  var draggeddata = document.getElementById(draggedDivId).getAttribute("tag");
  if (ev.target.tagName == "INPUT") {
    ev.target.value =ev.target.value+draggeddata;
  } else {
    var parentid=ev.target.parentElement.id;
    IsdraggedElementConatainsNode = document.getElementById(draggedDivId).getAttribute("node");
    var nodeelement = document.getElementById(ev.target.parentElement.id).getAttribute("node");
    var droppedelementdata = "";

    if (nodeelement) {
      droppedelementdata = document.getElementById(parentid).childNodes[1].innerHTML;
    } else {
      droppedelementdata = document.getElementById(parentid).childNodes[0].innerHTML;
    }

    var isConditionAddedForRepetableNode = document.getElementById(parentid).getAttribute("addedcondition");

    droppeddata = droppedelementdata;

    var draggeddataafterdrop = droppedelementdata.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
    var olddraggeddata = "";
    if (!nodeelement) {
      if (!droppedelementdata.toLowerCase().includes("_repeatablenode")) {
        olddraggeddata = droppedelementdata.slice(0, droppedelementdata.indexOf("=") + 1);
        draggedvalue = olddraggeddata + '"' + draggeddata + '"';

        document.getElementById(parentid).childNodes[0].innerHTML = draggedvalue;

        // $("#idMappedXmlTree").jstree('set_text',"#"+_parentNodeId ,draggedvalue);

        loadxmlforrepeatablenode();
        redomap.set(parentid, draggedvalue);
        undomap.set(parentid, droppeddata);
      } else {
        if (IsdraggedElementConatainsNode != null && IsdraggedElementConatainsNode != "" && IsdraggedElementConatainsNode != undefined) {
          olddraggeddata = droppedelementdata.slice(0, droppedelementdata.indexOf("=") + 1);
          if (isConditionAddedForRepetableNode) {
            draggedvalue = olddraggeddata + '"' + draggeddata + '[' + draggeddataafterdrop + ']"';
          } else {
            draggedvalue = olddraggeddata + '"' + draggeddata + '"';
          }

          document.getElementById(parentid).childNodes[0].innerHTML = draggedvalue;

          loadxmlforrepeatablenode();

          repeatablenode = [];
        }

      }


      if (dropid == "") {
        dropid =parentid;
        document.getElementById(parentid).firstChild.style.backgroundColor = "rgb(255,255,153)";

      }
      if (dropid != parentid) {

        document.getElementById(parentid).firstChild.style.backgroundColor = "rgb(255,255,153)";
        document.getElementById(dropid).firstChild.style.backgroundColor = null;
        dropid =parentid;
      }
    }
  }

}

function getChildNodeName(xmlnodelist) {
  for (var i = 0; i < xmlnodelist.length; i++) {
    if (xmlnodelist[i].childNodes.length > 0) {
      node.push(xmlnodelist[i].nodeName);
      getChildNodeName(xmlnodelist[i].childNodes);
    } else {
      node.push(xmlnodelist[i].nodeName);
    }
  }
  return node;
}

//toggle funtion is to make div collpsible
function toggle(btndata) {
  var childid = "";
  var isToggle = false;
  var getStyle = "";
  var parentdivdata = document.getElementById(btndata.id).parentElement;
  //Loading all childdiv of a parent div
  for (var i = 0; i < parentdivdata.childNodes.length; i++) {
    //intiatializing the child id
    childid = parentdivdata.childNodes[i].id;
    if (childid !== null && childid !== '' && childid !== undefined) {
      if (childid.startsWith("btn") && isToggle == false) {
        //getting current display style of the  child div
        getStyle = document.getElementById(childid).style.display;
        isToggle = true;
        if (getStyle == "block") {
          getStyle = "none";
          document.getElementById(childid).style.display = getStyle;
          document.getElementById(childid.replace("btn", "") + "btn").classList.remove('fa-caret-down');
          document.getElementById(childid.replace("btn", "") + "btn").classList.add('fa-caret-right');
        } else {
          getStyle = "block";
          document.getElementById(childid).style.display = getStyle;
          document.getElementById(childid.replace("btn", "") + "btn").classList.remove('fa-caret-right');
          document.getElementById(childid.replace("btn", "") + "btn").classList.add('fa-caret-down');
        }
      } else {
        document.getElementById(childid).style.display = getStyle;
      }
    }
  }
}

//Undo the latest dragged data
function undo() {

  if (undomap.size > 0) {
    var lastkeydata = Array.from(undomap)[undomap.size - 1][0];
    var lastvaluedata = Array.from(undomap)[undomap.size - 1][1];
    var attrvalue = lastvaluedata.trim().match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
    if (attrvalue != "") {
      document.getElementById(lastkeydata).style.backgroundColor = null;
    } else {
      document.getElementById(lastkeydata).style.backgroundColor = "rgb(124, 252, 0)";
    }
    document.getElementById(lastkeydata).childNodes[0].innerHTML = lastvaluedata;
    undobackup.set(lastkeydata, lastvaluedata);
    if (redobackup.size != undefined && redobackup.size > 0) {
      redomap.set(Array.from(redobackup)[redobackup.size - 1][0], Array.from(redobackup)[redobackup.size - 1][1]);
      redobackup.delete(Array.from(redobackup)[redobackup.size - 1][0]);
    }
    undomap.delete(lastkeydata);
  }

}
//Redo the latest dragged data

function redo() {

  if (redomap.size > 0) {

    var lastkeydata = Array.from(redomap)[redomap.size - 1][0];
    var lastvaluedata = Array.from(redomap)[redomap.size - 1][1];
    var attrvalue = lastvaluedata.trim().match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
    if (attrvalue != "") {
      document.getElementById(lastkeydata).style.backgroundColor = null;
    } else {
      document.getElementById(lastkeydata).style.backgroundColor = "rgb(124, 252, 0)";
    }
    document.getElementById(lastkeydata).childNodes[0].innerHTML = lastvaluedata;
    redobackup.set(lastkeydata, lastvaluedata);
    if (undobackup.size != undefined && undobackup.size > 0) {
      undomap.set(Array.from(undobackup)[undobackup.size - 1][0], Array.from(undobackup)[undobackup.size - 1][1])
      undobackup.delete(Array.from(undobackup)[undobackup.size - 1][0]);
    }
    redomap.delete(lastkeydata);
  }
}

function addcondition() {
  document.getElementById(attrid).setAttribute("addedCondition", "true");
}
var selectcount = 1;

function copyToClipboard(element) {
   var input = document.createElement('textarea');
    input.innerHTML = element;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
}

function copyToClipboardtable(element) {
   var input = document.createElement('textarea');
    input.innerHTML = element;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
}


//To check the variable has been added to variable node or not
function addingvariableforVariablenode(variablename, variablevalue) {
  var varcount = 0;
  var isVariableNodeContainsVariableValue = false;
  //Checking the variable has been inserted or not
  if (insertedvariablelist.length == 0) {
    //if variable is OBR_SpecialInstructions then add one more variable
    if (variablename.toLowerCase() == "OBR_SpecialInstructions".toLowerCase()) {
      insertedvariablelist.push("OBRid");
      createvariablediv("OBRid");
    }
    //inserting into variable list
    insertedvariablelist.push(variablename);
    variablemap.set(variablename, variablevalue);
    createvariablediv(variablename);
  } else {
    for (var i = 0; i < insertedvariablelist.length; i++) {
      //Checking the variable whether it is inserted or not
      if (variablename == insertedvariablelist[i]) {
        continue;
      } else {
        varcount++;
        continue;
      }
    }
    if (variablemap.size > 0) {
      for (let value of variablemap.values()) {
        if (value == variablevalue) {
          isVariableNodeContainsVariableValue = true;
          break;
        }
      }
    }
    //if variable is not inserted then push that variable to insertedvariablelist
    if (varcount == insertedvariablelist.length && varcount > 0 && !isVariableNodeContainsVariableValue) {
      if (variablename.toLowerCase() == "OBR_SpecialInstructions".toLowerCase()) {
        insertedvariablelist.push("OBRid");
        variablemap.set(variablename, variablevalue);
        createvariablediv("OBRid");
      }
      insertedvariablelist.push(variablename);
      variablemap.set(variablename, variablevalue);
      createvariablediv(variablename);
    }
  }
}
//adding variable div for variable node
function createvariablediv(variable) {

  if (variabledivid != null && variabledivid != "" && variabledivid != undefined) {

    var customlist = document.createElement("Li");
    _xmlNodeTreeCount++;
    customlist.id = _xmlNodeTreename + _xmlNodeTreeCount;
    _xmlNodeTreeCount++;
    customlist.setAttribute("tag","");
      customlist.setAttribute("node", "true");
      var spantag=document.createElement("span");
      spantag.className="caret";
      spantag.setAttribute("onclick","toggleCarret(this,event)");
      customlist.appendChild(spantag);
      spantag="";
      spantag=document.createElement("span");
      spantag.className="mappedxml";
      spantag.innerHTML="Variable";
      customlist.appendChild(spantag);

    document.getElementById(variabledivid).appendChild(customlist);

    var customul = document.createElement('ul');
    customul.setAttribute("operation", "attributes");
    customul.className = "nested";
    _xmlNodeTreeCount++;
    customul.id = _xmlNodeTreename + _xmlNodeTreeCount;
    _xmlNodeTreeCount++;

    document.getElementById(customlist.id).appendChild(customul);
    customlist = "";

    customlist = document.createElement("li");
    customlist.id = _xmlNodeTreename + _xmlNodeTreeCount;
    _xmlNodeTreeCount++;
    customlist.innerHTML = "<span class='mappedxml'>name="+'"' + variable + '"'+"</span>";
    document.getElementById(customul.id).appendChild(customlist);
    customlist = "";

    customlist = document.createElement("li");
    customlist.id = _xmlNodeTreename + _xmlNodeTreeCount;
    _xmlNodeTreeCount++;
    customlist.innerHTML = "<span class='mappedxml'>value="+'"' + variablemap.get(variable) + '"'+"</span>";
    
    document.getElementById(customul.id).appendChild(customlist);
  }
}



function createdivformappingvalue(Lookupttype, LookUpId) {

  var LookupType = "";
  var Lookupid = "";
  var attrvalue = document.getElementById(attrid).childNodes[0].innerHTML;
  var resultvalue = "";
  var stringoperarionparameter = "";
  var parametervalue = "";
  attrvalue = attrvalue.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
  LookupType = Lookupttype;
  Lookupid = LookUpId;
  if (attrvalue.toLowerCase().includes("getmappingvalue")) {
    var regExp = /(\(.*\))/;
    if (attrvalue.startsWith("_function")) {
      resultvalue = attrvalue.split(":");
      stringoperarionparameter = regExp.exec(resultvalue[1]);
    } else {
      stringoperarionparameter = regExp.exec(attrvalue);
    }
    parametervalue = stringoperarionparameter[0].replace("(", "").replace(")", "").split(',');
    LookupType = parametervalue[0].replace("'", "").replace("'", "");
    Lookupid = parametervalue[1].replace("'", "").replace("'", "");
  }

  // document.getElementById("inputText").style.display = "none";
  // LookupType = LookupType.replace("@", "");

  // if (document.getElementById("inputText").childNodes.length > 0) {
  //   if (document.getElementById("inputText").firstChild.id != "attrinput") {
  //     document.getElementById("inputText").firstChild.remove();
  //   }
  // }

  // var unorderlist = document.createElement("ul");
  // unorderlist.id = "mappinglist";
  // unorderlist.className = "token-input-list-facebook";

  // var getmappingdivlist = document.getElementsByClassName("GetMapping");
  // if (getmappingdivlist.length == 0) {
  //   createdivforoperation("LookUpId", Lookupid, "mapping", "GetMapping");
  //   createdivforoperation("LookUpType", LookupType, "mapping", "GetMapping");
  //   document.getElementById(attrid).setAttribute("mapping", "true");
  // }

  // document.getElementById("dropdownlist").style.display = "none";
  document.getElementById("idGetMappingOperation").style.display = "block";

  document.getElementById("idLookUpType").value = LookupType;
  document.getElementById("idLookUpId").value = Lookupid;
  document.getElementById(attrid).setAttribute("mapping", "true");
}


function AddInputValueToTextOnkeyDown(event, inputtextid, addtolistid, textclsname, operation) {
  var keyCode = (event.keyCode ? event.keyCode : event.which);
  var inputtext = "";
  if ((keyCode == 13) || (keyCode == 9)) {
    inputtext = document.getElementById(inputtextid).value;
    if (inputtext != "" && inputtext != null) {
      //appendText(inputtext, addtolistid, textclsname, operation);
      document.getElementById(inputtextid).value = "";
      document.getElementById(inputtextid).removeAttribute("onkeydown");
    }
  }
}

function AddInputValueToTextOnFocusOut(inputtextid, addtolistid, textclsname, operation) {
  inputtext = document.getElementById(inputtextid).value;
  if (inputtext != "" && inputtext != null) {
  //appendText(inputtext, addtolistid, textclsname, operation);
    document.getElementById(inputtextid).value = "";
    document.getElementById(inputtextid).removeAttribute("onfocusout");
  }
}


function AddDateFormat(ele) {

  dateFormatlistId = "";
  var isContainsValue = document.getElementById(stringdivid).childNodes.length;
  if (isContainsValue > 1) {
    var dateformatvalue = document.getElementById(ele.id).getAttribute("value");
    var dataformatlist = document.getElementsByClassName("DateFormatOperation");
    if (dataformatlist.length > 0) {
      dataformatlist[0].remove();
    }
    document.getElementById("dropdownlist").style.display = "none";
    //appendText(dateformatvalue, document.getElementById(stringdivid).parentNode.id, "", "DateFormatOperation");

    if (document.getElementById(textinputid).parentNode.parentNode.childElementCount > 1) {
      document.getElementById(textinputid).parentNode.nextSibling.firstChild.setAttribute("Dateformatlistid", ele.id);
    }

  } else {
    displayalertmsg("Please add Value");
  }

}


var dropdownidsp = "";

function checkXpathContainsRepeatableNode(nodeName, xpath) {

  var xpathRepeatableNode = "";
  var isContainsNode = "";
  var regex = "";
  for (var i = 0; i < defaultrepeatablenode.length; i++) {
    if (defaultrepeatablenode[i] != nodeName) {
      regex = new RegExp('\\b' + defaultrepeatablenode[i] + '\\b', 'g');
      isContainsNode = regex.exec(xpath);
      if (isContainsNode != null && isContainsNode.length > 0) {
        xpathRepeatableNode = xpath.substring(xpath.lastIndexOf(defaultrepeatablenode[i]) + defaultrepeatablenode[i].length, xpath.length);
      }
    }
  }
  for (var i = 0; i < repeatablenode.length; i++) {
    if (repeatablenode[i] != nodeName) {
      regex = new RegExp('\\b' + repeatablenode[i] + '\\b', 'g');
      isContainsNode = regex.exec(xpath);
      if (isContainsNode != null && isContainsNode.length > 0) {
        isParentNodeRepeatablenodeAdded = true;
      }

    }
  }
  return xpathRepeatableNode;
}

function displayalertmsg(alertmsg) {
  document.getElementById("alertMsg").style.visibility = "visible";
  document.getElementById("alertMsg").style.marginLeft = "15px";
  document.getElementById("alertMsg").innerHTML = alertmsg;
  clearalertmsg();
}

function clearalertmsg() {
  var msgtag = document.getElementById("alertMsg");
  setTimeout(function () {
    msgtag.innerHTML = "";
    msgtag.style.visibility = "hidden";
  }, 4000);
}


function loadxmlforrepeatablenode() {
  removeRepeatableflag = false;
  repetablenodexpath = "";
  removedRepeatablePath = "";
  isRepeatablenodeAdded = false;
  childNodesListForRemoveRepetableNode = [];
  childNodesListForRepetableNode = [];
  repeatablenodename = [];
  isParentNodeRepeatablenodeAdded = false;

  createMappedxml();

  var domParser = new DOMParser();
  if (xmldata != null && xmldata != "" && xmldata != undefined) {
    var xmlDocument = domParser.parseFromString(xmldata.trim(), "text/xml");

    var xmlnodes = xmlDocument.childNodes[0];

    RepeatableNode(removetextElement(xmlnodes.childNodes), xmlnodes.nodeName);
  }
}

function RepeatableNode(xmlchildnodelist, nodepath) {

  var removerepetableflag = false;
  var repetablenodeflag = false;
  var repeatablenodearray = [];
  var repeatablenodevalue = "";
  var guid = "";
  for (var i = 0; i < xmlchildnodelist.length; i++) {
    guid = "";
    removerepetableflag = false;
    repetablenodeflag = false;
    if (nodepath == "") {
      xPathRepeatable = "/" + xmlchildnodelist[i].nodeName;
    } else {
      xPathRepeatable = nodepath + "/" + xmlchildnodelist[i].nodeName;
    }
    if (repeatablenode.length > 0) {
      repeatablenodearray = [...repeatablenode];
    } else {
      repeatablenodearray = [...defaultrepeatablenode];
    }
    if (xmlchildnodelist[i].attributes["_NodeIdentifier"]) {
      guid = xmlchildnodelist[i].attributes["_NodeIdentifier"].nodeValue;
    }
    if (removerepeatablenode.length == 0) {
      for (var j = 0; j < repeatablenodearray.length; j++) {
        if (repeatablenodearray[j] == guid) {

          if (xmlchildnodelist[i].attributes["_RepeatableNode"]) {
            repetablenodeAdded = xmlchildnodelist[i].nodeName;
            repeatablenodevalue = xmlchildnodelist[i].attributes["_RepeatableNode"].nodeValue;
            repeatablenode = [];
            if (repeatablenodevalue != null && repeatablenodevalue != "" && repeatablenodevalue != undefined) {
              repetablenodexpath = repeatablenodevalue;

              isRepeatablenodeAdded = true;

              if (xmlchildnodelist[i].childNodes.length > 0) {
                childNodesListForRepetableNode = [];
                node = [];
                childNodesListForRepetableNode = getChildNodeName(xmlchildnodelist[i].childNodes);
              }
              if (xmlchildnodelist[i].attributes.length > 0) {
                replaceXpathWithRepeatableXpath(removetextElement(xmlchildnodelist[i].attributes), xmlchildnodelist[i].nodeName);
              }
            } else {
              repetablenodexpath = "";
            }

          }
        }
      }
    }

    if (childNodesListForRepetableNode.length > 0) {
      repetablenodeflag = GetRepeatableChildNodeList(xmlchildnodelist[i].nodeName, childNodesListForRepetableNode);
      if (repetablenodeflag) {
        childNodesListForRepetableNode = childNodesListForRepetableNode.filter(item => item !== xmlchildnodelist[i].nodeName);
      }
    }

    if (repetablenodeflag) {
      if (xmlchildnodelist[i].attributes.length > 0) {
        if (repetablenodexpath != null && repetablenodexpath != "" && repetablenodexpath != undefined) {
          replaceXpathWithRepeatableXpath(removetextElement(xmlchildnodelist[i].attributes), xmlchildnodelist[i].nodeName);
        }
      }
    }

    for (var j = 0; j < removerepeatablenode.length; j++) {
      repetablenodexpath = "";
      if (removerepeatablenode[j] == guid) {
        if (xmlchildnodelist[i].attributes.length > 0) {
          if (xmlchildnodelist[i].attributes["_RepeatableNode"]) {

            repeatablenodevalue = xmlchildnodelist[i].attributes["_RepeatableNode"].nodeValue;
            if (repeatablenodevalue != null && repeatablenodevalue != "" && repeatablenodevalue != undefined) {
              removedRepeatablePath = repeatablenodevalue;

              xmlchildnodelist[i].attributes.removeNamedItem("_RepeatableNode");
              removerepeatablenode = removerepeatablenode.filter(e => e !== guid);
              if (xmlchildnodelist[i].attributes.length > 0) {
                replacedotWithRepeatableXpath(removetextElement(xmlchildnodelist[i].attributes), xmlchildnodelist[i].nodeName);
              }
              if (xmlchildnodelist[i].childNodes.length > 0) {
                childNodesListForRemoveRepetableNode = [];
                node = [];
                childNodesListForRemoveRepetableNode = getChildNodeName(xmlchildnodelist[i].childNodes);
              }
            } else {
              removedRepeatablePath = "";
            }

          }
        }
      }
    }
    if (childNodesListForRemoveRepetableNode.length > 0) {
      removerepetableflag = GetRepeatableChildNodeList(xmlchildnodelist[i].nodeName, childNodesListForRemoveRepetableNode);
      if (removerepetableflag) {
        childNodesListForRemoveRepetableNode = childNodesListForRemoveRepetableNode.filter(item => item !== xmlchildnodelist[i].nodeName);
      }
    }

    if (removerepetableflag) {

      if (xmlchildnodelist[i].attributes.length > 0 && xmlchildnodelist[i].childNodes.length > 0) {
        if (xmlchildnodelist[i].attributes["_RepeatableNode"]) {
          repeatablenodename = [];
          node = [];
          repeatablenodename = getChildNodeName(xmlchildnodelist[i].childNodes);
        }
      }
      if (xmlchildnodelist[i].attributes.length > 0) {
        if (removedRepeatablePath != null && removedRepeatablePath != "" && removedRepeatablePath != undefined)
          replacedotWithRepeatableXpath(removetextElement(xmlchildnodelist[i].attributes), xmlchildnodelist[i].nodeName);
      }
    }
    if (xmlchildnodelist[i].childNodes.length > 0) {
      parentnodexpath = nodepath + "/" + xmlchildnodelist[i].nodeName;
      RepeatableNode(removetextElement(xmlchildnodelist[i].childNodes), parentnodexpath);
    }
  }
}

function replaceXpathWithRepeatableXpath(xmlattrlist, node) {
  var xmlattr = "";
  var xmlattrxpath = "";
  var xmlattrname = "";
  var attrxpath = "";
  var replacedXpath = "";
  var attrvalue = "";
  var regex = "";
  var splittedString = "";
  for (var k = 0; k < xmlattrlist.length; k++) {
    attrvalue = "";
    if (xmlattrlist[k].nodeName.toLowerCase() == "_addcondition" || xmlattrlist[k].nodeName.toLowerCase() == "_nodeidentifier") {
      continue;
    }
    if (xPathRepeatable != "") {
      attrxpath = xPathRepeatable + "/@" + xmlattrlist[k].nodeName;
    }
    //checking for attribute has root as xpath
    else {
      attrxpath = root + "/@" + xmlattrlist[k].nodeName;
    }
    attrxpath = attrxpath.replace("mappingData/", "").trim();
    if (!((xmlattrlist[k].nodeName.toLowerCase()) == "_RepeatableNode".toLowerCase())) {
        if (document.querySelectorAll('[tag="' + attrxpath + '"]').length > 1) {
          xmlattr = document.querySelectorAll('[tag="' + attrxpath + '"]')[1].childNodes[0].innerHTML;
        } else {
          xmlattr = document.querySelectorAll('[tag="' + attrxpath + '"]')[0].childNodes[0].innerHTML;
        }

      xmlattrxpath = xmlattr.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      xmlattrname = xmlattr.slice(0, xmlattr.indexOf("=") + 1);

    }
    if (!xmlattrlist[k].nodeValue.toLowerCase().includes("get")) {

      if ((xmlattrlist[k].nodeName.toLowerCase()) == "_RepeatableNode".toLowerCase()) {

        replacedXpath = checkXpathContainsRepeatableNode(node, repetablenodexpath);
        xmlattr = document.querySelectorAll('[tag="' + attrxpath + '"]')[0].childNodes[0].innerHTML;
        xmlattrxpath = xmlattr.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
        xmlattrname = xmlattr.slice(0, xmlattr.indexOf("=") + 1);

        if (replacedXpath !== null && replacedXpath !== '' && replacedXpath !== undefined) {
          attrvalue = xmlattrname + '"' + xmlattrxpath.replace(repetablenodexpath, "." + replacedXpath) + '"';
          repetablenodexpath = "." + replacedXpath;
        } else if (isParentNodeRepeatablenodeAdded) {
          attrvalue = xmlattrname + '"' + xmlattrxpath.replace(repetablenodexpath, ".") + '"';
          isParentNodeRepeatablenodeAdded = false;
        }
        if (attrvalue != "") {
          document.querySelectorAll('[tag="' + attrxpath + '"]')[0].childNodes[0].innerHTML = attrvalue;
          attrvalue = "";
        }

      } else {
        replacedXpath = checkXpathContainsRepeatableNode(node, repetablenodexpath);
        if (replacedXpath !== null && replacedXpath !== '' && replacedXpath !== undefined) {
          attrvalue = xmlattrname + '"' + xmlattrxpath.replace("." + replacedXpath, ".") + '"';
        } else {
          if (repetablenodexpath.startsWith(".") && !xmlattrxpath.startsWith(".")) {
            regex = new RegExp('\\b' + repetablenodexpath.replace(".", "") + '\\b', 'g');
            splittedString = xmlattrxpath.split(regex);
            attrvalue = xmlattrname + '"' + "." + splittedString[1] + '"';
          } else {
            attrvalue = xmlattrname + '"' + xmlattrxpath.replaceAll(repetablenodexpath, ".") + '"';
          }
        }

      }

    } else if (xmlattrlist[k].nodeValue.toLowerCase().includes("getmappingvalue")) {
      var nodevalue = xmlattrlist[k].nodeValue.split(',');
      nodevalue = nodevalue[0] + "," + nodevalue[1].replace(repetablenodexpath, ".");
      attrvalue = xmlattrname + '"' + nodevalue + '"';
    } else if (xmlattrlist[k].nodeValue.toLowerCase().includes("getconvertdatetime")) {
      var nodevalue = xmlattrlist[k].nodeValue.split(',');
      if (!(nodevalue[0].split("(")[1].startsWith("."))) {
        nodevalue = nodevalue[0].replace(repetablenodexpath, ".") + "," + nodevalue[1] + "," + nodevalue[2];
        attrvalue = xmlattrname + '"' + nodevalue + '"';
      }
    } else {
      attrvalue = xmlattrlist[k].nodeValue.replaceAll(repetablenodexpath, ".");
    }

    if (attrvalue != "") {
        if (document.querySelectorAll('[tag="' + attrxpath + '"]').length > 1) {
          document.querySelectorAll('[tag="' + attrxpath + '"]')[1].childNodes[0].innerHTML = attrvalue;
        } else {
          document.querySelectorAll('[tag="' + attrxpath + '"]')[0].childNodes[0].innerHTML = attrvalue;
        }
    }
  }
}

function replacedotWithRepeatableXpath(xmlattrlist, node) {
  var xmlattr = "";
  var xmlattrxpath = "";
  var xmlattrname = "";
  var replacedXpath = "";
  isRepeatableNode = false;
  var _parentHasRepeatableNode = false;
  var attrvalue = "";
  for (var k = xmlattrlist.length - 1; k >= 0; k--) {
    if (xmlattrlist[k].nodeName.toLowerCase() == "_addcondition") {
      continue;
    }
    if (xPathRepeatable != "") {
      attrxpath = xPathRepeatable + "/@" + xmlattrlist[k].nodeName;
    }
    //checking for attribute has root as xpath
    else {
      attrxpath = root + "/@" + xmlattrlist[k].nodeName;
    }
    attrvalue = "";
    attrxpath = attrxpath.replace("mappingData/", "").trim();
    if (!(xmlattrlist[k].nodeName.toLowerCase() == "_repeatablenode")) {
        if (document.querySelectorAll('[tag="' + attrxpath + '"]').length > 1) {
          xmlattr = document.querySelectorAll('[tag="' + attrxpath + '"]')[1].childNodes[0].innerHTML;
        } else {
          xmlattr = document.querySelectorAll('[tag="' + attrxpath + '"]')[0].childNodes[0].innerHTML;
        }
      xmlattrxpath = xmlattr.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      xmlattrname = xmlattr.slice(0, xmlattr.indexOf("=") + 1);
    }
    if (xmlattrlist[k].nodeValue.startsWith(".") && !xmlattrlist[k].nodeValue.toLowerCase().includes("get")) {
      if (xmlattrlist[k].nodeName.toLowerCase() == "_repeatablenode") {

        xmlattr = document.querySelectorAll('[tag="' + attrxpath + '"]')[0].childNodes[0].innerHTML;

        xmlattrxpath = xmlattr.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");

        xmlattrname = xmlattr.slice(0, xmlattr.indexOf("=") + 1);

        _parentHasRepeatableNode = GetRepeatableChildNodeList(node, repeatablenodename);

        if (!_parentHasRepeatableNode) {

          replacedXpath = checkXpathContainsRepeatableNode(removedRepeatablePath);

          if (replacedXpath == "") {
            attrvalue = xmlattrname + '"' + xmlattrxpath.replace(".", removedRepeatablePath) + '"';
          } else {
            attrvalue = xmlattrname + '"' + xmlattrxpath.replace(removedRepeatablePath, "." + replacedXpath) + '"';
            removedRepeatablePath = "." + replacedXpath;
          }
          if (attrvalue != "") {
            document.querySelectorAll('[tag="' + attrxpath + '"]')[0].childNodes[0].innerHTML = attrvalue;
            attrvalue = "";
          }
        }
        isRepeatableNode = true;
      } else {
        _parentHasRepeatableNode = GetRepeatableChildNodeList(node, repeatablenodename);
        if (!_parentHasRepeatableNode) {
          replacedXpath = checkXpathContainsRepeatableNode(node, removedRepeatablePath);

          if (replacedXpath == "" && !isRepeatableNode) {
            attrvalue = xmlattrname + '"' + xmlattrxpath.replace(".", removedRepeatablePath) + '"';
          } else {
            continue;
          }
        }
      }
    } else if (xmlattrlist[k].nodeValue.toLowerCase().includes("getmappingvalue")) {
      var nodevalue = xmlattrlist[k].nodeValue.split(',');
      if (nodevalue[1].startsWith(".")) {
        nodevalue = nodevalue[0] + "," + nodevalue[1].replace(".", removedRepeatablePath);
        attrvalue = xmlattrname + '"' + nodevalue + '"';
      }

    } else if (xmlattrlist[k].nodeValue.toLowerCase().includes("getconvertdatetime")) {
      var nodevalue = xmlattrlist[k].nodeValue.split(',');
      if (nodevalue[0].split("(")[1].startsWith(".")) {
        nodevalue = nodevalue[0].replace(".", removedRepeatablePath) + "," + nodevalue[1] + "," + nodevalue[2];
        attrvalue = xmlattrname + '"' + nodevalue + '"';
      }
    } else {
      attrvalue = xmlattrname + '"' +ReplaceAll(xmlattrlist[k].nodeValue,removedRepeatablePath) + '"';
    }
    if (attrvalue != "") {
        if (document.querySelectorAll('[tag="' + attrxpath + '"]').length > 1) {
          document.querySelectorAll('[tag="' + attrxpath + '"]')[1].childNodes[0].innerHTML = attrvalue;
        } else {
          document.querySelectorAll('[tag="' + attrxpath + '"]')[0].childNodes[0].innerHTML = attrvalue;
        }
    }
  }
}


function GetRepeatableChildNodeList(nodename, repetablearray) {
  var _parentHasRepeatableNode = false;

  for (var i = 0; i < repetablearray.length; i++) {
    if (repetablearray[i] == nodename) {
      _parentHasRepeatableNode = true;
      break;
    }
  }
  return _parentHasRepeatableNode;
}


function AddVariable(ele){
  var inputId=ele.parentNode.attributes["inputId"].nodeValue;
  document.getElementById(inputId).value="$"+ele.firstChild.innerHTML;
  if( document.getElementById(inputId).getAttribute("addedXpath")){
    document.getElementById(inputId).removeAttribute("addedXpath");
  }
  document.getElementById(inputId).setAttribute("addedVariable",true);
  document.getElementById(inputId).readOnly=false;
}

function addVariableTodropDown() {
  var count = 0;
  $('.idVariablesubMenu').empty();
  for (var i = 0; i < insertedvariablelist.length; i++) {
    var customlist = document.createElement("LI");
    customlist.className = "dropdown-item";
    customlist.setAttribute("onclick","AddVariable(this)")

    var customarchortag = document.createElement("a");
    count++;
    customarchortag.id = "variable" + count;
    customarchortag.innerHTML = insertedvariablelist[i];
    customlist.appendChild(customarchortag);
   
    $('.idVariablesubMenu').append(customlist);
  }
}


function appendVariabletoVariableNode() {
  var variablename = document.getElementById("idVariablename").value;
  var variablevalue = document.getElementById("idVariablevalue").value;
  var isEmptyflag = false;
  isEmptyflag = checkisemptyornot(variablename);
  isEmptyflag = checkisemptyornot(variablevalue);
  if (!isEmptyflag) {
    addingvariableforVariablenode(variablename, variablevalue);
    addVariableTodropDown();
    document.getElementById("modalVariable").style.display = "none";
  }
}

function checkisemptyornot(_isEmptyString) {
  if (_isEmptyString == null || _isEmptyString == "" || _isEmptyString == undefined) {
    return true;
  } else {
    return false;
  }
}

function closeVariablemodel() {
  document.getElementById("idVariablename").value = "";
  document.getElementById("idVariablevalue").value = "";
  document.getElementById("modalVariable").style.display = "none";
}

function createrootNode(nodeDivid, operation) {

  var customul = document.createElement("UL");
  var parentid = "";
  _xmlNodeTreeCount++;
  customul.id = _xmlNodeTreename + _xmlNodeTreeCount;
  customul.setAttribute("operation", operation);
  customul.className = "nested";

  parentid = _xmlNodeTreename + _xmlNodeTreeCount;

  if (nodeDivid != null && nodeDivid != "" && nodeDivid != undefined) {
    document.getElementById(nodeDivid).appendChild(customul);
  }
  var nodeName = document.getElementById(nodeDivid).childNodes[1].innerHTML;
  if (nodeName == root) {
    customul.className = "nested active";
    $("#" + nodeDivid).children('span').addClass("caret-down");
  } else if (nodeName == "HL7Message") {
    customul.className = "nested active";
    $("#" + nodeDivid).children('span').addClass("caret-down");
  } else if (nodeName.toLowerCase() == "variables") {
    variabledivid = parentid;
  }
  _xmlNodeTreeCount++;
  return parentid;
}

function creatlistNode(attrname, parentdivid, xpath, isLastAttribute) {

  var listDivid = "";
  var customlist = document.createElement("li");
  var _parentlistId = "";
  var guid = "";
  var spantag="";
  if (document.getElementById(parentdivid).parentNode) {
    _parentlistId = document.getElementById(parentdivid).parentNode.id;
  }

  _xmlNodeTreeCount++;
  customlist.id = _xmlNodeTreename + _xmlNodeTreeCount;
  listDivid = _xmlNodeTreename + _xmlNodeTreeCount;
  var addClick=(name,tag)=>{
      if (name.toLowerCase()!= "variables" && name.toLowerCase()!="variable") {
        tag.setAttribute("onclick", "displayvalue(this,event)");
      } else {
        tag.setAttribute("onclick", "AddGlobalVariable(this,event)");
    }
  }
  if (mappedxml) {
    customlist.setAttribute("tag", xpath.replace("mappingData/", ""));
    if (_isAttr) {
      customlist.setAttribute("ondrop", "drop(event)");
      customlist.setAttribute("ondragover", "allowDrop(event)");
      
      spantag="";
      spantag=document.createElement("span");
      spantag.innerHTML=attrname;
      spantag.className="mappedxml";

      customlist.appendChild(spantag);
      var count=0;
      ignoreattribute.forEach(ele=>{
        var nodename = attrname.slice(0, attrname.indexOf("="));
        if(ele!=nodename.toLowerCase()){
          count++;
        }
      });
      if(count==ignoreattribute.length){
        addClick(attrname,spantag);
      }
      if(attrname.toLowerCase().includes("_nodeidentifier")){
        customlist.style.display="none";
      }
      
      if (isContainsNodeIdentifier && mappedxml) {
        guid = CreateGuid();
        document.getElementById(_parentlistId).setAttribute("nodeidentifier", guid);
        defaultrepeatablenode.push(guid);
      }
      
      // customlist.innerHTML=attrname;

    } else {
      customlist.setAttribute("node", "true");

      spantag="";
      spantag=document.createElement("span");
      spantag.className="caret";
      spantag.setAttribute("onclick","toggleCarret(this,event)");
      customlist.appendChild(spantag);
      spantag="";
      spantag=document.createElement("span");
      spantag.className="mappedxml";
      spantag.innerHTML=attrname;
      var count=0;
      ignoreattribute.forEach(ele=>{
        var nodename = attrname.trim();
        if(ele!=nodename.toLowerCase()){
          count++;
        }
      });
      if(count==ignoreattribute.length){
        addClick(attrname,spantag);
      }
      customlist.appendChild(spantag);
    }

    if (document.getElementById(_parentlistId).childNodes.length > 0) {
      if(attrname!=root){
        var nodeName = document.getElementById(_parentlistId).childNodes[1].innerHTML;
        var attrvalue = attrname.slice(0, attrname.indexOf("="));
        var nodeValue = attrname.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
        var isEmptyflag = false;
        isEmptyflag = checkisemptyornot(nodeName);

        if (!isEmptyflag) {
          if (nodeName.toLowerCase() == "variable" && attrvalue == "name")
            insertedvariablelist.push(nodeValue);
        }
      }
    }
    if (isLastAttribute == "true") {
      customlist.setAttribute("isLastAttribute", "true");
    }


  } else if (!mappedxml) {
    customlist.setAttribute("tag", xpath);
    customlist.setAttribute("draggable", "true");
    customlist.setAttribute("ondragstart", "drag(event)");
    if (_isAttr) {
      spantag="";
      spantag=document.createElement("span");
      spantag.className="templatexml";
      spantag.innerHTML=attrname;
      customlist.appendChild(spantag);
    } else {
      spantag="";
      spantag=document.createElement("span");
      spantag.className="caret";
      spantag.setAttribute("onclick","toggleCarret(this,event)");
      customlist.appendChild(spantag);
      spantag="";
      spantag=document.createElement("span");
      spantag.className="templatexml";
      spantag.innerHTML=attrname;
      customlist.appendChild(spantag);
    }
  }



  var appendList = () => {
    document.getElementById(parentdivid).appendChild(customlist);
    if (isContainsNodeIdentifier && mappedxml) {
    createAttribute(guid, "_NodeIdentifier", _parentlistId);
    }
    if(attrname.toLowerCase().includes("_nodeidentifier")){
      var nodeguid=attrname.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      document.getElementById(_parentlistId).setAttribute("nodeidentifier", nodeguid);
      document.getElementById(_parentlistId).setAttribute("_nodeidentifierListId",customlist.id);
    }
  }

  if (parentdivid != null && parentdivid != "" && parentdivid != undefined) {
    var isemptyflag = checkisemptyornot(attrid);
    if (!isemptyflag) {
      if (parentdivid == attrid) {
        var nodeType = document.getElementById(attrid).getAttribute("nodeType");

        if (nodeType == "nextNode") {
          document.getElementById(parentdivid).insertAdjacentElement("afterend", customlist);
        } else if ("previousNode") {
          document.getElementById(parentdivid).insertAdjacentElement("beforebegin", customlist);
        } else {
          appendList();
        }
      } else {
        appendList();
      }
    } else {
      appendList();
    }
  }
  if(spantag!="" && spantag!=undefined && mappedxml){
    if(spantag.parentNode.parentNode.parentNode.childNodes[1]!=undefined){
      var nodeName=spantag.parentNode.parentNode.parentNode.childNodes[1].innerHTML;
      if(nodeName.toLowerCase()=="variable"){
       spantag.removeAttribute("onclick");
       var attrvalue=spantag.innerHTML.substring(0,spantag.innerHTML.indexOf("="));
       if(attrvalue=="name"){
        spantag.parentNode.parentNode.parentNode.setAttribute("variablenamelist",customlist.id);
       }else{
        spantag.parentNode.parentNode.parentNode.setAttribute("variablevaluelist",customlist.id);
       }
      }
    }
  }


  if (attrname.toLowerCase().includes("_addcondition") && mappedxml) {

    document.getElementById(_parentlistId).setAttribute("addedCondition", "true");
    document.getElementById(_parentlistId).setAttribute("_addconditionid", customlist.id);

  } else if (attrname.toLowerCase().includes("_repeatablenode") && mappedxml) {
    //Creating a attribute for parent div by adding repeatablenode is true
    document.getElementById(_parentlistId).setAttribute("repeatablenode", "true");
    //Creating a attribute for parent div by adding repeatable attribute id
    document.getElementById(_parentlistId).setAttribute("repeatableid", customlist.id);
    //Adding repeatable flag for parent div
    document.getElementById(_parentlistId).setAttribute("repeatableflag", "true");
  }
  _xmlNodeTreeCount++;
  return listDivid;
}


var xmldata = "";

function createMappedxml() {

  xmldata = "";
  var xmlTreeData = document.getElementById("idMappedXmlDiv").childNodes;

  getXmlFromlist(xmlTreeData, "");

  return xmldata;

}

function toggleCarret(ele, event) {
  event.stopPropagation();
  ele.parentElement.querySelector(".nested").classList.toggle("active");
  ele.classList.toggle("caret-down");
}


function getXmlFromlist(xmlTreeData, xml) {
  var operation = "";
  xmldata = xml;
  var isEmptyflag=false;
  for (var i = 0; i < xmlTreeData.length; i++) {
    isEmptyflag=checkisemptyornot(xmlTreeData[i].className);
    if (xmlTreeData[i].tagName == "SPAN") {
      if(isEmptyflag){
        continue;
      }
      else if(xmlTreeData[i].className.indexOf("mappedxml")>-1){
        if (xmlTreeData[i].parentNode.attributes["node"]!=undefined) {
          xmldata += xmlTreeData[i].innerHTML;
        } else {
          xmldata += " " + xmlTreeData[i].innerHTML;
        }
      }
    }
    if (xmlTreeData[i].nodeName!="#text") {
      isEmptyflag=checkisemptyornot(xmlTreeData[i].attributes["operation"]);
      if (!isEmptyflag) {
        operation = xmlTreeData[i].attributes["operation"].value;
      }
      if (xmlTreeData[i].attributes["node"]!=undefined) {
        xmldata += "<";
      } else if (operation == "childnodes") {
        xmldata += ">";
      }
      if (xmlTreeData[i].parentNode.attributes["node"]!=undefined && operation == "void") {
        xmldata += "/>";
      }
    }

    if (xmlTreeData[i].childNodes.length > 0) {
      getXmlFromlist(xmlTreeData[i].childNodes, xmldata);

      if (xmlTreeData[i].parentNode.attributes["operation"]!=undefined) {

        operation = xmlTreeData[i].parentNode.attributes["operation"].value;
        if (operation == "childnodeswithattr" && xmlTreeData[i].attributes["isLastAttribute"]!=undefined) {
          xmldata += ">";
        }
        if (operation == "attributes" && i == xmlTreeData.length - 1) {
          xmldata += "/>";
        } else if ((operation == "childnodes" || operation == "childnodeswithattr") && i == xmlTreeData.length - 1) {
          xmldata += "</" + xmlTreeData[i].parentNode.parentNode.childNodes[1].innerHTML + ">";
        }
      }
    }
  }
}

function enableDivControl(ele) {
  document.getElementById("idMappingOperationdetails").innerHTML = ele.innerHTML;
  switch (ele.innerHTML) {
    case "Alphanumeric":
      AddGetAlphanumeric();
      break;
    case "Concat":
      AddConcatAttr();
      break;
    case "ConcatNodeAttributes":
      AddConcatNodeAttributes();
      break;
    case "Count":
      AddCount();
      break;
    case "ConvertDateTime":
      AddDateFormatAttr();
      break;
    case "ConvertDateTimeRoundOff":
      AddConvertDateTimeRoundOff();
      break;
    case "CurrentDateTime":
      AddCurrentDateFormatAttr();
      break;
    case "CurrentDateTimeRoundOff":
      AddCurrentDateTimeRoundOff();
      break;
    case "DownloadFileFromCore":
      AddDownloadFile();
      break;
    case "ReadEmbeddedContent":
      AddEmbeddedContent();
      break;
    case "Length":
      AddLength();
      break;
    case "Mapping":
      AddMapping();
      break;
    case "PdfFromLIS":
      AddPdf();
      break;
    case "ReadHeader":
      AddHeader();
      break;
    case "RemoveExtraSpace":
      AddRemoveExtraSpace();
      break;
    case "Replace":
      AddReplace();
      break;
    case "Split":
      AddGetSplit();
      break;
    case "String Constant":
      AddStringConstant();
      break;
    case "Ternary Operation":
      AddTernaryOpAttr();
      break;
    case "Variable":
      AddVariableOP();
      break;
  }
}

function AddMapping() {
  var attrvalue = document.getElementById("idattrInput").value;
  if (attrvalue != null && attrvalue != "" && attrvalue != undefined) {
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      document.getElementById("idGetMappingOperation").style.display = "block";
      var tag = document.getElementById(attrid).getAttribute("tag");
      var attrhtml = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrname = attrhtml.slice(0, attrhtml.indexOf("="));
      document.getElementById("idLookUpType").value = attrname;
      document.getElementById("idLookUpId").value = tag;
      document.getElementById(attrid).setAttribute("mapping", "true");
    }
  }
}

function saveMappedData() {

  // var attrvalue = document.getElementById("idattrInput").value;
  // if (attrvalue != null && attrvalue != "" && attrvalue != undefined) {

  var mappedAttr = document.getElementById(attrid).getAttribute("mapping");
  var getsplitAttr = document.getElementById(attrid).getAttribute("getsplit");
  var alphaNumericAttr = document.getElementById(attrid).getAttribute("getalphanumeric");
  var isNodeAttr = document.getElementById(attrid).getAttribute("node");
  var getMatchingConditionAttr = document.getElementById(attrid).getAttribute("getmatchingvalue");
  var dateFormatAttr = document.getElementById(attrid).getAttribute("dateformat");
  var currentDateFormatAttr = document.getElementById(attrid).getAttribute("currentdateformat");
  var concatAttr = document.getElementById(attrid).getAttribute("concat");
  var attrinnerHTML = "";
  if (isNodeAttr) {
    attrinnerHTML = document.getElementById(attrid).childNodes[1].innerHTML;
  } else {
    attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
  }

  var attrname = attrinnerHTML.slice(0, attrinnerHTML.indexOf("=") + 1);

  var countAttr = document.getElementById(attrid).getAttribute("count");
  var replaceAttr = document.getElementById(attrid).getAttribute("replace");
  var strlengthAttr = document.getElementById(attrid).getAttribute("strlength");
  var convertDateFormatRoundOffAttr = document.getElementById(attrid).getAttribute("dateformatroundoff");
  var currentDateFormatRoundOffAttr = document.getElementById(attrid).getAttribute("currentdateformatroundoff");
  var concatNodeAttr = document.getElementById(attrid).getAttribute("ConcatNodeAttributes");
  var readHeaderAttr = document.getElementById(attrid).getAttribute("ReadHeader");
  var removeExtraSpaceAttr = document.getElementById(attrid).getAttribute("RemoveExtraSpace");
  var stringConstantAttr = document.getElementById(attrid).getAttribute("StringConstant");
  var pdfAttr = document.getElementById(attrid).getAttribute("Pdf");
  var downloadFileAttr = document.getElementById(attrid).getAttribute("DownloadFile");
  var embeddedContentAttr = document.getElementById(attrid).getAttribute("EmbeddedContent");
  var variableAttr = document.getElementById(attrid).getAttribute("variable");
  var nodeVariableAttr=document.getElementById(attrid).getAttribute("nodeVariable");
  var attrVariable=document.getElementById(attrid).getAttribute("attrvariable");

  if (mappedAttr) {
    var lookuptype = document.getElementById("idLookUpType").value;
    var lookupid = document.getElementById("idLookUpId").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(lookuptype);
    _isemptyflag = checkisemptyornot(lookuptype);

    if (!_isemptyflag) {

      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetMappingValue('" + lookuptype + "'," + lookupid + ")" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "_function:GetMappingValue('" + lookuptype + "'," + lookupid + ")" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";

    }

  } else if (getsplitAttr) {
    var input = document.getElementById("idGetSplitInput").value;
    var charecter = document.getElementById("idGetSplitCharecter").value;
    var index = document.getElementById("idGetSplitIndex").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(input);
    _isemptyflag = checkisemptyornot(charecter);
    _isemptyflag = checkisemptyornot(index);
    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetSplit(" + input + ",'" + charecter + "'," + index + ")" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "_function:GetSplit(" + input + ",'" + charecter + "'," + index + ")" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
 } else if (alphaNumericAttr) {
    var input = document.getElementById("idGetAlphanumericInput").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(input);
    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetAlphanumeric(" + input + ")" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid,  attrname + '"' + "_function:GetAlphanumeric(" + input + ")" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
  } else if (getMatchingConditionAttr) {

    var condition = document.getElementById("idgetMatchingConditionValue").value;
    var trueValue = document.getElementById("idgetMatchingConditiontrueValue").value;
    var falseValue = document.getElementById("idgetMatchingConditionfalseValue").value;

    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(condition);
    _isemptyflag = checkisemptyornot(trueValue);
    _isemptyflag = checkisemptyornot(falseValue);
    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetMatchingConditionValue('" + condition + "','" + trueValue + "','" + falseValue + "')" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "_function:GetMatchingConditionValue('" + condition + "','" + trueValue + "','" + falseValue + "')" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }

  } else if (dateFormatAttr || convertDateFormatRoundOffAttr) {
    var input = document.getElementById("idDateInput").value;
    var currentdateformat = document.getElementById("idDateCurrentInputFormat").value;
    var expectedformat = document.getElementById("idDateRequiredOutputFormat").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(input);
    _isemptyflag = checkisemptyornot(currentdateformat);
    _isemptyflag = checkisemptyornot(expectedformat);
    if (!_isemptyflag) {
      _isemptyflag = checkisemptyornot(convertDateFormatRoundOffAttr);
      if (!_isemptyflag) {
        document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetConvertDateTimeRoundOff(" + input + ",'" + currentdateformat + "','" + expectedformat + "')" + '"';
        redomap.set(attrid, attrname + '"' + "_function:GetConvertDateTimeRoundOff(" + input + ",'" + currentdateformat + "','" + expectedformat + "')" + '"');
      } else {
        document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetConvertDateTime(" + input + ",'" + currentdateformat + "','" + expectedformat + "')" + '"';
        redomap.set(attrid,  attrname + '"' + "_function:GetConvertDateTime(" + input + ",'" + currentdateformat + "','" + expectedformat + "')" + '"');
      }
      undomap.set(attrid, attrinnerHTML);


      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
  } else if (currentDateFormatAttr || currentDateFormatRoundOffAttr) {
    var input = document.getElementById("idCurrentDateInput").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(input);
    if (!_isemptyflag) {
      _isemptyflag = checkisemptyornot(currentDateFormatRoundOffAttr);
      if (!_isemptyflag) {
        document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetCurrentDateTimeRoundOff(" + input + ")" + '"';
        redomap.set(attrid,attrname + '"' + "_function:GetCurrentDateTimeRoundOff(" + input + ")" + '"');
      } else {
        document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetCurrentDateTime(" + input + ")" + '"';
        redomap.set(attrid, attrname + '"' + "_function:GetCurrentDateTime(" + input + ")" + '"');
      }
      undomap.set(attrid, attrinnerHTML);
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }

  } else if (concatAttr) {
    var childnodecount = document.getElementById("idConcatOperation").childNodes.length;
    var concatString = "";
    for (var i = 1; i <= childnodecount; i++) {
      if (i > 1) {
        concatString = concatString + ",";
      }
      if (document.getElementById("idStringParameter" + i)) {
        if(document.getElementById("idStringParameter" + i).getAttribute("addedXpath")!=null){
          concatString = concatString + document.getElementById("idStringParameter" + i).value;
          document.getElementById(attrid).setAttribute("StringParameter"+i+"type","Xpath");
        }
        else if(document.getElementById("idStringParameter" + i).getAttribute("addedVariable")!=null){
          concatString = concatString + document.getElementById("idStringParameter" + i).value;
          document.getElementById(attrid).setAttribute("StringParameter"+i+"type","Variable");
        }
        else{
          concatString = concatString + "'" + document.getElementById("idStringParameter" + i).value + "'";
        }
        
      }

    }
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(concatString);
    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "concat(" + concatString + ")" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "concat(" + concatString + ")" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
  } else if (countAttr) {
    var xPath = document.getElementById("idNodePath").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(xPath);
    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "count(" + xPath + ")" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "count(" + xPath + ")" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
  } else if (downloadFileAttr) {
    var input = document.getElementById("idDownloadFileInput").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(input);
    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetDownloadFileFromCore(" + input + ")" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid,  attrname + '"' + "_function:GetDownloadFileFromCore(" + input + ")" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
  } else if (embeddedContentAttr) {
    var embeddeddata = document.getElementById("idEmbeddeddata").value;
    var accession = document.getElementById("idEmbeddedContentAccession").value;
    var filename = document.getElementById("idEmbeddedContentFilename").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(embeddeddata);
    _isemptyflag = checkisemptyornot(accession);
    _isemptyflag = checkisemptyornot(filename);
    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].nodeValue = attrname + '"' + "_function:GetEmbeddedContent(" + embeddeddata + "," + accession + ",'" + filename + "')" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid,   attrname + '"' + "_function:GetEmbeddedContent(" + embeddeddata + "," + accession + ",'" + filename + "')" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
  } else if (replaceAttr) {
    var input = document.getElementById("idReplaceInput").value;
    var string = document.getElementById("idReplaceString").value;
    var replacewith = document.getElementById("idReplaceWith").value;

    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(input);
    _isemptyflag = checkisemptyornot(string);
    _isemptyflag = checkisemptyornot(replacewith);

    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetReplace(" + input + ",'" + string + "','" + replacewith + "')" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "_function:GetReplace(" + input + ",'" + string + "','" + replacewith + "')" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none"
    }
  } else if (strlengthAttr) {
    var string = document.getElementById("idInputStr").value;

    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(string);
    if (!_isemptyflag) {

      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "string-length(" + string + ")" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "string-length(" + string + ")" + '"');

      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none"

    }

  } else if (concatNodeAttr) {
    var xPath = document.getElementById("idConcatNodeXpath").value;
    var condition = document.getElementById("idConcatNodeCondition").value;
    var Value = document.getElementById("idConcatNodeValue").value;
    var separator = document.getElementById("idConcatNodeSeparator").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(xPath);
    _isemptyflag = checkisemptyornot(condition);
    _isemptyflag = checkisemptyornot(Value);
    _isemptyflag = checkisemptyornot(separator);

    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_xsltfunction:ConcatNodeAttributes(" + xPath + "#" + condition + "#" + Value + "#" + separator + ")" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "_xsltfunction:ConcatNodeAttributes(" + xPath + "#" + condition + "#" + Value + "#" + separator + ")" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none"
    }
  } else if (readHeaderAttr) {
    var input = document.getElementById("idReadHeaderInput").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(input);
    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetHeader(" + input + ")" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "_function:GetHeader(" + input + ")" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
  } else if (stringConstantAttr) {
    var input = document.getElementById("idStringConstantInput").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(input);
    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "'" + input + "'" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "'" + input + "'" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
  } else if (removeExtraSpaceAttr) {
    var input = document.getElementById("idRemoveExtraSpaceInput").value;
    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(input);
    if (!_isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetRemoveExtraSpaces(" + input + ")" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "_function:GetRemoveExtraSpaces(" + input + ")" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
  } else if (pdfAttr) {

    var RequestID = document.getElementById("idGetPdfRequestID").value;
    var Type = document.getElementById("idGetPdfType").value;
    var RequestGUID = document.getElementById("idGetPdfRequestGUID").value;
    var LabOrgGUID = document.getElementById("idGetPdfLabOrgGUID").value;
    var CaseGUID = document.getElementById("idGetPdfCaseGUID").value;
    var Key = document.getElementById("idGetPdfKey").value;

    var _isemptyflag = false;
    _isemptyflag = checkisemptyornot(RequestID);
    _isemptyflag = checkisemptyornot(Type);
    _isemptyflag = checkisemptyornot(RequestGUID);
    _isemptyflag = checkisemptyornot(LabOrgGUID);
    _isemptyflag = checkisemptyornot(CaseGUID);
    _isemptyflag = checkisemptyornot(Key);
    if (!isemptyflag) {
      document.getElementById(attrid).childNodes[0].innerHTML = attrname + '"' + "_function:GetPdf(" + RequestID + "," + Type + "," + RequestGUID + "," + LabOrgGUID + "," + CaseGUID + ",'" + Key + "')" + '"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid, attrname + '"' + "_function:GetPdf(" + RequestID + "," + Type + "," + RequestGUID + "," + LabOrgGUID + "," + CaseGUID + ",'" + Key + "')" + '"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }
  }else if(attrVariable){
    var variableName=document.getElementById("idAttributeVariableName").value;
    var isEmpty = false;
    isEmpty = checkisemptyornot(variableName);

    if(!isEmpty){
      document.getElementById(attrid).childNodes[0].innerHTML = attrname +'"'+variableName+'"';
      undomap.set(attrid, attrinnerHTML);
      redomap.set(attrid,+'"'+variableName+'"');
      clearMappedXmlOperation();
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }

  }
   else if (isNodeAttr) {
    var isRepeatableNodeChecked = document.getElementById("idrepeatable").checked;
    var isAddConditionChecked = document.getElementById("idcondition").checked;
    var isRepeatableNode = document.getElementById(attrid).getAttribute("repeatablenode");
    var repeatableid=document.getElementById(attrid).getAttribute("repeatableid");
    var CheckRepeatable=()=>{
      if (isRepeatableNodeChecked && !isRepeatableNode) {
        var xpathForRepeatableNode = document.getElementById("idSourceXpathInput").value;
        if (xpathForRepeatableNode != null && xpathForRepeatableNode != "" && xpathForRepeatableNode != undefined) {
          if(repeatableid!=null && repeatableid!='' && repeatableid!=undefined){
            document.getElementById(repeatableid).firstChild.innerHTML='_RepeatableNode='+'"'+xpathForRepeatableNode+'"';
            document.getElementById(repeatableid).style.display="block";
            document.getElementById(repeatableid).firstChild.classList.add("mappedxml")
            document.getElementById(attrid).setAttribute("repeatablenode", "true");
            document.getElementById(attrid).setAttribute("repeatableflag", "true");
          }
          else{
            createAttribute(xpathForRepeatableNode, "_RepeatableNode", attrid);
          }
         
          var guid = CreateGuid();
          createAttribute(guid, "_NodeIdentifier", attrid);
          document.getElementById(attrid).setAttribute("nodeidentifier",guid);
          repeatablenode.push(guid);
  
          loadxmlforrepeatablenode();
          document.getElementById("idrepeatable").checked = false;
          document.getElementById("idSourceXpathInput").value = "";
          document.getElementById("idDivRepeatable").style.display = "none";
          document.getElementById("idSourceXpathText").style.display = "none";
          document.getElementById("idattrInput").value = "";
          document.getElementById("idDivExpression").style.display = "none"
  
        }
      }
      }
     var conditioncheck=()=>{if(isAddConditionChecked) {
      var condition1 = document.getElementById("idConditionExpression1").value;
      var isEmpty = false;
      isEmpty = checkisemptyornot(condition1);
      if (!isEmpty) {
        createAttribute(condition1, "_AddCondition", attrid);
        loadxmlforrepeatablenode();
        document.getElementById("idcondition").checked = false;
        document.getElementById("idDivAddCondition").style.display = "none";
        document.getElementById("idConditionExpression").style.display = "none";
        document.getElementById("idConditionExpression1").value = "";
        document.getElementById("idattrInput").value = "";
        document.getElementById("idDivExpression").style.display = "none";
      }
    }}
      var globalvariablecheck=()=>{
        if (variableAttr) {

          var name = document.getElementById("idGlobalVariableName").value;
          var value = document.getElementById("idGlobalVariableValue").value;
    
          var isEmptyflag=false;
    
          isEmptyflag=checkisemptyornot(name);
          isEmptyflag=checkisemptyornot(value);
    
          var isContainsValue=false;
    
          if(!isEmptyflag){
            if(variablemap.has(name)!=true)
            {
            for(var values of variablemap.values()){
              if(values.toLowerCase()==value.toLowerCase()){
                isContainsValue=true;
              }
            }
             if(!isContainsValue){
                addingvariableforVariablenode(name, value);
                document.getElementById("idattrInput").value = "";
                document.getElementById("idDivExpression").style.display = "none";
             }
            }
          }
        }
      }
      var nodevariablecheck=()=>{
      if(nodeVariableAttr){
      var name = document.getElementById("idGlobalVariableName").value;
      var value = document.getElementById("idGlobalVariableValue").value;

      var isEmptyflag=false;

      isEmptyflag=checkisemptyornot(name);
      isEmptyflag=checkisemptyornot(value);



      if(!isEmptyflag){
        var attrvalue=name+":"+value;
        createAttribute(attrvalue,"_Variable",attrid);
      }
      document.getElementById("idattrInput").value = "";
      document.getElementById("idDivExpression").style.display = "none";
    }}
    var removerepeatablenodecheck=()=>{ 
      if (!isRepeatableNodeChecked) {
      if (isRepeatableNode) {
        var repeatablelistid = document.getElementById(attrid).getAttribute("repeatableid");
        var nodeidentifier = document.getElementById(attrid).getAttribute("nodeidentifier");
        var nodeidentifierListId = document.getElementById(attrid).getAttribute("_nodeidentifierListId")
        removerepeatablenode.push(nodeidentifier);

        defaultrepeatablenode = defaultrepeatablenode.filter(e => e !== nodeidentifier);

        loadxmlforrepeatablenode();
        document.getElementById(repeatablelistid).firstChild.innerHTML = "_RepeatableNode=" + '"' + '"';
        document.getElementById(repeatablelistid).style.display = "none";
        document.getElementById(attrid).removeAttribute("repeatablenode");

        document.getElementById(attrid).removeAttribute("repeatableflag");
        document.getElementById(attrid).removeAttribute("nodeidentifier");
        document.getElementById(attrid).removeAttribute("_nodeidentifierListId");
        document.getElementById(repeatableid).firstChild.classList.remove("mappedxml");
        var isemptyflag = false;
        isemptyflag = checkisemptyornot(nodeidentifierListId);
        if (!isemptyflag) {
          document.getElementById(nodeidentifierListId).remove();
        }
        document.getElementById("idattrInput").value = "";
        document.getElementById("idDivExpression").style.display = "none";
        document.getElementById("idDivRepeatable").style.display = "none";
        document.getElementById("idrepeatable").checked = false;
        document.getElementById("idBtnSnippetDiv").style.display = "none";
      }
    }
  }
  CheckRepeatable();
  conditioncheck();
  globalvariablecheck();
  nodevariablecheck();
  removerepeatablenodecheck();
  }
}

function AddGetSplit() {

  var attrvalue = document.getElementById("idattrInput").value;
  var input = "";
  var charecter = "";
  var index = "";
  var parametervalue = "";
  var isEmptyflag = false;

  if (attrvalue != null && attrvalue != "" && attrvalue != undefined) {
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      document.getElementById("idGetSplitOperation").style.display = "block";
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      if (attrvalue.startsWith("_function") && attrvalue.includes("GetSplit")) {
        parametervalue = getResultParameterfromfunction(attrid);
        isEmptyflag = checkisemptyornot(parametervalue)
        if (!isEmptyflag || parametervalue.length > 0) {
          input = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
          charecter = parametervalue[1].replace("'", "").replace("'", "");
          index = parametervalue[2].replace("'", "").replace("'", "").replace(")", "");
        }
      } else {
        input = document.getElementById(attrid).getAttribute("tag");
      }

      document.getElementById("idGetSplitInput").value = input;
      document.getElementById("idGetSplitCharecter").value = charecter;
      document.getElementById("idGetSplitIndex").value = index;

      document.getElementById(attrid).setAttribute("getsplit", "true");
    }
  }
}

function AddGetAlphanumeric() {
  var attrvalue = document.getElementById("idattrInput").value;
  var input = "";
  var parametervalue = "";
  var isEmptyflag = false;
  if (attrvalue != null && attrvalue != "" && attrvalue != undefined) {
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      document.getElementById("idGetAlphanumeric").style.display = "block";
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      if (attrvalue.startsWith("_function") && attrvalue.includes("GetAlphanumeric")) {
        parametervalue = getResultParameterfromfunction(attrid);
        isEmptyflag = checkisemptyornot(parametervalue)
        if (!isEmptyflag || parametervalue.length > 0) {
          input = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
        }
      } else {
        input = document.getElementById(attrid).getAttribute("tag");
      }
      document.getElementById("idGetAlphanumericInput").value = input;
      document.getElementById(attrid).setAttribute("getalphanumeric", "true");
    }
  }

}

function AddTernaryOpAttr() {
  var attrvalue = document.getElementById("idattrInput").value;
  var condition = "";
  var trueValue = "";
  var falseValue = "";
  var parametervalue = "";
  var isEmptyflag = false;
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      document.getElementById("idgetMatchingCondition").style.display = "block";
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      if (attrvalue.startsWith("_function") && attrvalue.includes("GetMatchingConditionValue")) {
        parametervalue = getResultParameterfromfunction(attrid);
        isEmptyflag = checkisemptyornot(parametervalue);
        if (!isEmptyflag || parametervalue.length > 0) {
          condition = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
          trueValue = parametervalue[1].replace("'", "").replace("'", "");
          falseValue = parametervalue[2].replace("'", "").replace("'", "").replace(")", "");
        }
      }

      document.getElementById("idgetMatchingConditionValue").value = condition;
      document.getElementById("idgetMatchingConditiontrueValue").value = trueValue;
      document.getElementById("idgetMatchingConditionfalseValue").value = falseValue;

      document.getElementById(attrid).setAttribute("getmatchingvalue", "true");
    }
}


function AddDateFormatAttr() {

  var attrInput = document.getElementById("idattrInput").value;
    if (attrid != null && attrid != "" && attrid != undefined) {
      DateTimeOperation();
      document.getElementById(attrid).setAttribute("dateformat", "true");
    }
}

function AddCurrentDateFormatAttr() {
  var attrInput = document.getElementById("idattrInput").value;
    if (attrid != null && attrid != "" && attrid != undefined) {
      CurrentDateTimeOperation();
      document.getElementById(attrid).setAttribute("currentdateformat", "true");
    }
}

function AddConcatAttr() {
  var attrvalue = document.getElementById("idattrInput").value;
  var parameter1 = "";
  var parameter2 = "";
  var parameter = "";
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      document.getElementById("idConcatOperation").style.display = "block";
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      if (attrvalue.includes("concat")) {
        var regExp = new RegExp('[^\(]*(\(.*\))[^\)]*', 'g');
        var stringoperarionparameter = regExp.exec(attrvalue);
        var parametervalue = stringoperarionparameter[1].split(',');

        isEmptyflag = checkisemptyornot(parametervalue);
        if (!isEmptyflag || parametervalue.length > 0) {
          parameter1 = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
          parameter2 = parametervalue[1].replace("'", "").replace("'", "").replace(")", "");;
          if (parametervalue.length > 2) {
            for (var i = 2; i < parametervalue.length; i++) {
              parameter = parametervalue[i].replace("'", "").replace("'", "").replace("(", "").replace(")", "");
              if(document.getElementById(attrid).getAttribute("StringParameter"+(i+1)+"type")=="Xpath"){
                AddTextbox("", i, parametervalue.length, parameter,"addedXpath");
              }
              else if(document.getElementById(attrid).getAttribute("StringParameter"+(i+1)+"type")=="Variable"){
                AddTextbox("", i, parametervalue.length, parameter,"addedVariable");
              }
              else {
                AddTextbox("", i, parametervalue.length, parameter,"");
              }
            }
          }
        }
      }
      if(document.getElementById(attrid).getAttribute("StringParameter1type")=="Xpath"){
        document.getElementById("idStringParameter1").setAttribute("addedXpath",true);
        document.getElementById("idStringParameter1").readOnly=false;
      }
      else if(document.getElementById(attrid).getAttribute("StringParameter1type")=="Variable"){
        document.getElementById("idStringParameter1").setAttribute("addedVariable",true);
        document.getElementById("idStringParameter1").readOnly=false;
      }
      if(document.getElementById(attrid).getAttribute("StringParameter2type")=="Xpath"){
        document.getElementById("idStringParameter2").setAttribute("addedXpath",true);
        document.getElementById("idStringParameter2").readOnly=false;
      }
      else if(document.getElementById(attrid).getAttribute("StringParameter2type")=="Variable"){
        document.getElementById("idStringParameter2").setAttribute("addedVariable",true);
        document.getElementById("idStringParameter2").readOnly=false;
      }
      document.getElementById("idStringParameter1").value = parameter1;
      document.getElementById("idStringParameter2").value = parameter2;

      document.getElementById(attrid).setAttribute("concat", "true");
    }
}

function AddCount() {
  var attrInput = document.getElementById("idattrInput").value;
  var xPath = "";
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      document.getElementById("idCount").style.display = "block";
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      if (attrvalue.includes("count")) {
        var regExp = new RegExp('[^\(]*(\(.*\))[^\)]*', 'g');

        var stringoperarionparameter = regExp.exec(attrvalue);

        var parametervalue = stringoperarionparameter[1].split(',');

        xPath = parametervalue[0].replace("'", "").replace("'", "").replace("(", "").replace(")", "");;
      } else {
        xPath = document.getElementById(attrid).getAttribute("tag");
      }
      document.getElementById("idNodePath").value = xPath;

      document.getElementById(attrid).setAttribute("count", "true");

    }
}

function AddReplace() {
  var attrvalue = document.getElementById("idattrInput").value;
  var input = "";
  var stringtobereplace = "";
  var replacewith = "";
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      document.getElementById("idReplace").style.display = "block";
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      if (attrvalue.startsWith("_function") && attrvalue.includes("GetReplace")) {
        parametervalue = getResultParameterfromfunction(attrid);
        isEmptyflag = checkisemptyornot(parametervalue);
        if (!isEmptyflag || parametervalue.length > 0) {
          input = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
          stringtobereplace = parametervalue[1].replace("'", "").replace("'", "").replace("(", "");
          replacewith = parametervalue[2].replace("'", "").replace("'", "").replace(")", "");
        }
      }
      document.getElementById("idReplaceInput").value = input;
      document.getElementById("idReplaceString").value = stringtobereplace;
      document.getElementById("idReplaceWith").value = replacewith;

      document.getElementById(attrid).setAttribute("replace", "true");
    }
}

function AddLength() {
  var attrInput = document.getElementById("idattrInput").value;

  var string = "";
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      document.getElementById("idStringLength").style.display = "block";
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      if (attrvalue.toLowerCase().includes("string-length")) {

        var regExp = new RegExp('[^\(]*(\(.*\))[^\)]*', 'g');

        var stringoperarionparameter = regExp.exec(attrvalue);

        var parametervalue = stringoperarionparameter[1].split(',');

        string = parametervalue[0].replace("'", "").replace("'", "").replace("(", "").replace(")", "");

      } else {
        string = document.getElementById(attrid).getAttribute("tag");
      }

      document.getElementById("idInputStr").value = string;

      document.getElementById(attrid).setAttribute("strlength", "true");

    }

}

function AddConcatNodeAttributes() {

  var attrInput = document.getElementById("idattrInput").value;
  var xPath = "";
  var condition = "";
  var Value = "";
  var separator = "";

    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      document.getElementById("idConcatNodeAttributes").style.display = "block";
      if (attrvalue.startsWith("_xsltfunction") && attrvalue.includes("ConcatNodeAttributes")) {
        var regExp = new RegExp('[^\(]*(\(.*\))[^\)]*', 'g');

        var stringoperarionparameter = regExp.exec(attrvalue);

        var parametervalue = stringoperarionparameter[1].split('#');
        xPath = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
        condition = parametervalue[1].replace("'", "").replace("'", "").replace("(", "");
        Value = parametervalue[2].replace("'", "").replace("'", "").replace("(", "");
        separator = parametervalue[3].replace("'", "").replace("'", "").replace(")", "");
      } else {
        xPath = document.getElementById(attrid).getAttribute("tag");
      }

      document.getElementById("idConcatNodeXpath").value = xPath;
      document.getElementById("idConcatNodeCondition").value = condition;
      document.getElementById("idConcatNodeValue").value = Value;
      document.getElementById("idConcatNodeSeparator").value = separator;

      document.getElementById(attrid).setAttribute("ConcatNodeAttributes", "true");

    }
}

function AddConvertDateTimeRoundOff() {
  var attrInput = document.getElementById("idattrInput").value;

    if (attrid != null && attrid != "" && attrid != undefined) {
      DateTimeOperation();
      document.getElementById(attrid).setAttribute("dateformatroundoff", "true");
    }
  }

function AddCurrentDateTimeRoundOff() {
  var attrInput = document.getElementById("idattrInput").value;
    if (attrid != null && attrid != "" && attrid != undefined) {
      CurrentDateTimeOperation();
      document.getElementById(attrid).setAttribute("currentdateformatroundoff", "true");
    }
}

function AddHeader() {
  var attrvalue = document.getElementById("idattrInput").value;
  var input = "";
  var parametervalue = "";
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      document.getElementById("idReadHeader").style.display = "block";
      if (attrvalue.startsWith("_function") && attrvalue.includes("GetHeader")) {
        parametervalue = getResultParameterfromfunction(attrid);
        isEmptyflag = checkisemptyornot(parametervalue);
        if (!isEmptyflag || parametervalue.length > 0) {
          input = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
        }
      } else {
        input = document.getElementById(attrid).getAttribute("tag");
      }
      document.getElementById("idReadHeaderInput").value = input;
      document.getElementById(attrid).setAttribute("ReadHeader", "true");
    }
}

function AddRemoveExtraSpace() {
  var attrvalue = document.getElementById("idattrInput").value;
  var input = "";
  var parametervalue = "";
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      document.getElementById("idRemoveExtraSpace").style.display = "block";
      if (attrvalue.startsWith("_function") && attrvalue.includes("GetRemoveExtraSpaces")) {
        parametervalue = getResultParameterfromfunction(attrid);
        isEmptyflag = checkisemptyornot(parametervalue);
        if (!isEmptyflag || parametervalue.length > 0) {
          input = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
        }
      } else {
        input = document.getElementById(attrid).getAttribute("tag");
      }
      document.getElementById("idRemoveExtraSpaceInput").value = input;
      document.getElementById(attrid).setAttribute("RemoveExtraSpace", "true");
    }
}

function AddStringConstant() {
  var attrvalue = document.getElementById("idattrInput").value;
  var input = "";
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      document.getElementById("idStringConstant").style.display = "block";
      if (attrvalue.startsWith("'") && attrvalue.endsWith("'")) {
        attrvalue = attrvalue.replace("'", "").replace("'", "");
        isEmptyflag = checkisemptyornot(attrvalue);
        if (!isEmptyflag) {
          input = attrvalue;
        }
      } else {
        input = document.getElementById(attrid).getAttribute("tag");
      }
      document.getElementById("idStringConstantInput").value = input;
      document.getElementById(attrid).setAttribute("StringConstant", "true");
    }
}

function AddPdf() {
  var attrvalue = document.getElementById("idattrInput").value;
  var RequestID = "";
  var Type = "";
  var RequestGUID = "";
  var LabOrgGUID = "";
  var CaseGUID = "";
  var Key = "";
  var parametervalue = "";
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      document.getElementById("idGetPdf").style.display = "block";
      if (attrvalue.startsWith("_function") && attrvalue.includes("GetPdf")) {
        parametervalue = getResultParameterfromfunction(attrid);
        isEmptyflag = checkisemptyornot(parametervalue);
        if (!isEmptyflag && parametervalue.length > 0) {
          RequestID = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
          Type = parametervalue[1].replace("'", "").replace("'", "").replace("(", "");
          RequestGUID = parametervalue[2].replace("'", "").replace("'", "").replace("(", "");
          LabOrgGUID = parametervalue[3].replace("'", "").replace("'", "").replace("(", "");
          CaseGUID = parametervalue[4].replace("'", "").replace("'", "").replace("(", "");
          Key = parametervalue[5].replace("'", "").replace("'", "").replace("(", "").replace(")", "");
        }
      }


      document.getElementById("idGetPdfRequestID").value = RequestID;
      document.getElementById("idGetPdfType").value = Type;
      document.getElementById("idGetPdfRequestGUID").value = RequestGUID;
      document.getElementById("idGetPdfLabOrgGUID").value = LabOrgGUID;
      document.getElementById("idGetPdfCaseGUID").value = CaseGUID;
      document.getElementById("idGetPdfKey").value = Key;

      document.getElementById(attrid).setAttribute("Pdf", "true");
    }
}

function AddDownloadFile() {
  var attrvalue = document.getElementById("idattrInput").value;
  var input = "";
  var parametervalue = "";
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      document.getElementById("idDownloadFile").style.display = "block";
      if (attrvalue.startsWith("_function") && attrvalue.includes("GetDownloadFileFromCore")) {
        parametervalue = getResultParameterfromfunction(attrid);
        isEmptyflag = checkisemptyornot(parametervalue);
        if (!isEmptyflag || parametervalue.length > 0) {
          input = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
        }
      } else {
        input = document.getElementById(attrid).getAttribute("tag");
      }
      document.getElementById("idDownloadFileInput").value = input;
      document.getElementById(attrid).setAttribute("DownloadFile", "true");
    }
}

function AddEmbeddedContent() {
  var attrvalue = document.getElementById("idattrInput").value;
  var embeddeddata = "";
  var accession = "";
  var filename = "";
  var parametervalue = "";
    if (attrid != null && attrid != "" && attrid != undefined) {
      clearMappedXmlOperation();
      var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
      var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      document.getElementById("idEmbeddedContent").style.display = "block";
      if (attrvalue.startsWith("_function") && attrvalue.includes("GetEmbeddedContent")) {
        parametervalue = getResultParameterfromfunction(attrid);
        isEmptyflag = checkisemptyornot(parametervalue);
        if (!isEmptyflag || parametervalue.length > 0) {
          embeddeddata = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
          accession = parametervalue[1].replace("'", "").replace("'", "").replace("(", "");
          filename = parametervalue[2].replace("'", "").replace("'", "").replace(")", "");
        }
      }
      document.getElementById("idEmbeddeddata").value = embeddeddata;
      document.getElementById("idEmbeddedContentAccession").value = accession;
      document.getElementById("idEmbeddedContentFilename").value = filename;

      document.getElementById(attrid).setAttribute("EmbeddedContent", "true");
    }
}

function AddVariableOP(){
  var _variableName="";
  if (attrid != null && attrid != "" && attrid != undefined) {
    clearMappedXmlOperation();
    var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
    var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
    document.getElementById("idAttributeVariable").style.display = "block";
    if(attrvalue.startsWith("$")){
      _variableName=attrvalue.substring(1,attrvalue.length);
    }
    document.getElementById("idAttributeVariableName").value=_variableName;
    document.getElementById(attrid).setAttribute("attrvariable","true");
  }
}


function clearMappedXmlOperation() {
  if (attrid != null && attrid != "" && attrid != undefined) {

    var isMappedAttr = "";
    var isSplitAttr = "";
    var isAlphaNumericAttr = "";
    var isDateFormatAttr = "";
    var isCurrentDateFormatAttr = "";
    var concatAttr = "";
    var countAttr = "";
    var replaceAttr = "";
    var isStringLengthAttr = "";
    var isconvertDateFormatRoundOffAttr = "";
    var iscurrentDateFormatRoundOffAttr = "";
    var concatNodeAttr = "";
    var isReadHeaderAttr = "";
    var isRemoveExtraSpaceAttr = "";
    var isStringConstantAttr = "";
    var isPdfAttr = "";
    var isDownloadFileAttr = "";
    var isEmbeddedContentAttr = "";
    var isVariableAttr = "";
    var isnodeVariableAttr="";
    var isAttrVariable="";
    var getMatchingConditionAttr = "";

    isMappedAttr = document.getElementById(attrid).getAttribute("mapping");
    isSplitAttr = document.getElementById(attrid).getAttribute("getsplit");
    isAlphaNumericAttr = document.getElementById(attrid).getAttribute("getalphanumeric");
    getMatchingConditionAttr = document.getElementById(attrid).getAttribute("getmatchingvalue");
    isDateFormatAttr = document.getElementById(attrid).getAttribute("dateformat");
    isCurrentDateFormatAttr = document.getElementById(attrid).getAttribute("currentdateformat");
    concatAttr = document.getElementById(attrid).getAttribute("concat");
    countAttr = document.getElementById(attrid).getAttribute("count");
    replaceAttr = document.getElementById(attrid).getAttribute("replace");
    isStringLengthAttr = document.getElementById(attrid).getAttribute("strlength");
    isconvertDateFormatRoundOffAttr = document.getElementById(attrid).getAttribute("dateformatroundoff");
    iscurrentDateFormatRoundOffAttr = document.getElementById(attrid).getAttribute("currentdateformatroundoff");
    concatNodeAttr = document.getElementById(attrid).getAttribute("ConcatNodeAttributes");
    isReadHeaderAttr = document.getElementById(attrid).getAttribute("ReadHeader");
    isRemoveExtraSpaceAttr = document.getElementById(attrid).getAttribute("RemoveExtraSpace");
    isStringConstantAttr = document.getElementById(attrid).getAttribute("StringConstant");
    isPdfAttr = document.getElementById(attrid).getAttribute("Pdf");
    isDownloadFileAttr = document.getElementById(attrid).getAttribute("DownloadFile");
    isVariableAttr = document.getElementById(attrid).getAttribute("variable");
    isnodeVariableAttr=document.getElementById(attrid).getAttribute("nodeVariable");
    isEmbeddedContentAttr = document.getElementById(attrid).getAttribute("EmbeddedContent");
    isAttrVariable=document.getElementById(attrid).getAttribute("attrvariable");


    document.getElementById("idDivRepeatable").style.display = "none";
    document.getElementById("idDivAddCondition").style.display = "none";
    document.getElementById("idBtnSnippetDiv").style.display = "none";
    document.getElementById("idSnippet").style.display = "none";
    document.getElementById("idVariableNode").style.display = "none";


    if (isMappedAttr) {
      document.getElementById("idGetMappingOperation").style.display = "none";
      document.getElementById("idLookUpType").value = "";
      document.getElementById("idLookUpId").value = "";
      document.getElementById(attrid).removeAttribute("mapping");
    } else if (isSplitAttr) {
      document.getElementById("idGetSplitOperation").style.display = "none";
      document.getElementById("idGetSplitInput").value = "";
      document.getElementById("idGetSplitCharecter").value = "";
      document.getElementById("idGetSplitIndex").value = "";
      document.getElementById(attrid).removeAttribute("getsplit");
    } else if (isAlphaNumericAttr) {
      document.getElementById("idGetAlphanumeric").style.display = "none";
      document.getElementById("idGetAlphanumericInput").value = "";
      document.getElementById(attrid).removeAttribute("getalphanumeric");
    } else if (getMatchingConditionAttr) {
      document.getElementById("idgetMatchingCondition").style.display = "none";
      document.getElementById("idgetMatchingConditionValue").value = "";
      document.getElementById("idgetMatchingConditiontrueValue").value = "";
      document.getElementById("idgetMatchingConditionfalseValue").value = "";
      document.getElementById(attrid).removeAttribute("getmatchingvalue");
    } else if (isDateFormatAttr || isconvertDateFormatRoundOffAttr) {
      document.getElementById("idDateFormat").style.display = "none";
      document.getElementById("idDateInput").value = "";
      document.getElementById("idDateCurrentInputFormat").value = "";
      document.getElementById("idDateRequiredOutputFormat").value = "";
      document.getElementById(attrid).removeAttribute("dateformat");
      if (isconvertDateFormatRoundOffAttr) {
        document.getElementById(attrid).removeAttribute("dateformatroundoff");
      }
    } else if (isCurrentDateFormatAttr || iscurrentDateFormatRoundOffAttr) {
      document.getElementById("idCurrentDateFormat").style.display = "none";
      document.getElementById("idCurrentDateInput").value = "";
      document.getElementById(attrid).removeAttribute("currentdateformat");
      if (iscurrentDateFormatRoundOffAttr) {
        document.getElementById(attrid).removeAttribute("currentdateformatroundoff");
      }
    } else if (concatAttr) {
      document.getElementById("idStringParameter1").value = "";
      document.getElementById("idStringParameter1").readOnly=true;
      document.getElementById("idStringParameter2").readOnly=true;
      document.getElementById("idStringParameter2").value = "";
      
      document.getElementById("idConcatOperation").style.display = "none";
      document.getElementById(attrid).removeAttribute("concat");
      var childnodecount = document.getElementById("idConcatOperation").childNodes.length;
      var childNodes = document.getElementById("idConcatOperation").childNodes;
      var isAddedPlusicon = false;
      if (childnodecount > 2) {
        for (var i = 0; i < childNodes.length; i++) {
          if (i > 1) {
            childNodes[i].remove();
            i--;
          }
          if (i == 1 && !isAddedPlusicon) {
            var customcoldiv = "";
            customcoldiv = document.createElement("DIV");
            customcoldiv.id = "idAddTextBox";
            customcoldiv.className = "col-1";
            customcoldiv.innerHTML = "<i class='fa fa-plus'  aria-hidden='true' onclick='AddTextbox(this)'></i>";
            childNodes[i].appendChild(customcoldiv);
            isAddedPlusicon = true;
          }
        }
      }
    } else if (countAttr) {
      document.getElementById("idNodePath").value = "";
      document.getElementById("idCount").style.display = "none";
      document.getElementById(attrid).removeAttribute("count");
    } else if (isDownloadFileAttr) {
      document.getElementById("idDownloadFileInput").value = "";
      document.getElementById("idDownloadFile").style.display = "none";
      document.getElementById(attrid).removeAttribute("DownloadFile");
    } else if (isEmbeddedContentAttr) {
      document.getElementById("idEmbeddeddata").value = "";
      document.getElementById("idEmbeddedContentAccession").value = "";
      document.getElementById("idEmbeddedContentFilename").value = "";
      document.getElementById("idEmbeddedContent").style.display = "none";
      document.getElementById(attrid).removeAttribute("EmbeddedContent");
    } else if (replaceAttr) {
      document.getElementById("idReplaceInput").value = "";
      document.getElementById("idReplaceString").value = "";
      document.getElementById("idReplaceWith").value = "";

      document.getElementById("idReplace").style.display = "none";

      document.getElementById(attrid).removeAttribute("replace");

    } else if (isStringLengthAttr) {
      document.getElementById("idInputStr").value = "";
      document.getElementById("idStringLength").style.display = "none";
      document.getElementById(attrid).removeAttribute("strlength");
    } else if (concatNodeAttr) {
      document.getElementById("idConcatNodeXpath").value = "";
      document.getElementById("idConcatNodeCondition").value = "";
      document.getElementById("idConcatNodeValue").value = "";
      document.getElementById("idConcatNodeSeparator").value = "";

      document.getElementById("idConcatNodeAttributes").style.display = "none";

      document.getElementById(attrid).removeAttribute("ConcatNodeAttributes");
    } else if (isReadHeaderAttr) {
      document.getElementById("idReadHeaderInput").value = "";
      document.getElementById("idReadHeader").style.display = "none";
      document.getElementById(attrid).removeAttribute("ReadHeader");
    } else if (isRemoveExtraSpaceAttr) {
      document.getElementById("idRemoveExtraSpaceInput").value = "";
      document.getElementById("idRemoveExtraSpace").style.display = "none";
      document.getElementById(attrid).removeAttribute("RemoveExtraSpace");
    } else if (isStringConstantAttr) {
      document.getElementById("idStringConstantInput").value = "";
      document.getElementById("idStringConstant").style.display = "none";
      document.getElementById(attrid).removeAttribute("StringConstant");
    } else if (isPdfAttr) {
      document.getElementById("idGetPdfRequestID").value = "";
      document.getElementById("idGetPdfType").value = "";
      document.getElementById("idGetPdfRequestGUID").value = "";
      document.getElementById("idGetPdfLabOrgGUID").value = "";
      document.getElementById("idGetPdfCaseGUID").value = "";
      document.getElementById("idGetPdfKey").value = "";
      document.getElementById("idGetPdf").style.display = "none";
      document.getElementById(attrid).removeAttribute("Pdf");
    } else if (isVariableAttr || isnodeVariableAttr) {
      document.getElementById("idGlobalVariableName").value = "";
      document.getElementById("idGlobalVariableValue").value = "";

      document.getElementById("idGlobalVariable").style.display = "none";
      if(isnodeVariableAttr){
        document.getElementById("idVariableNodeCheck").checked=false;
      }
      document.getElementById(attrid).removeAttribute("variable");
    }
    else if(isAttrVariable){
      document.getElementById("idAttributeVariableName").value="";
      document.getElementById("idAttributeVariable").style.display="none";
      document.getElementById(attrid).removeAttribute("attrvariable");
    }
  }
}

function getResultParameterfromfunction(id) {

  var stringoperarionparameter = "";
  var attrinnerHTML = document.getElementById(id).childNodes[0].innerHTML;
  var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
  var parametervalue = "";
  var regExp = new RegExp('[^\(]*(\(.*\))[^\)]*', 'g');
  var resultvalue = attrvalue.split(":");

  stringoperarionparameter = regExp.exec(resultvalue[1]);
  parametervalue = stringoperarionparameter[1].split(',');
  return parametervalue;

}


function AddTextbox(ele, index, length, parameter,type) {

  if (ele == "") {
    if (document.getElementById("idAddTextBox")) {
      document.getElementById("idAddTextBox").remove();
    }
  } else {
    ele.parentNode.remove();
  }
  var childNodeCount = document.getElementById("idConcatOperation").childNodes.length;
  var customrowdiv = document.createElement("DIV");
  customrowdiv.className = "row pt-2";

  var customdiv=document.createElement("DIV");
  customdiv.className="input-group";

  var customcoldiv = document.createElement("DIV");
  customcoldiv.className = "col-sm-2"

  var customlabel = document.createElement("label");
  customlabel.innerHTML = "Parameter " + (childNodeCount + 1);

  customcoldiv.appendChild(customlabel);

  customrowdiv.appendChild(customcoldiv);

  customcoldiv = "";

  customcoldiv = document.createElement("DIV");
  customcoldiv.className = "col-sm-8";

  var customcoldivforinput= document.createElement("DIV");
  customcoldivforinput.className="col-sm-10 pr-0";

  var custominput = document.createElement("input");
  custominput.type = "text";
  custominput.className = "form-control";
  custominput.id = "idStringParameter" + (childNodeCount + 1);
  custominput.placeholder = "String";
  if(!checkisemptyornot(type)){
    custominput.setAttribute(type,true);
    custominput.readOnly=false;
  }
  else{
    custominput.readOnly=true;
  }
  
  custominput.setAttribute("ondrop","drop(event)");
  custominput.setAttribute("ondragover","allowDrop(event)");
  custominput.autocomplete = "off";
 
  customcoldivforinput.appendChild(custominput);
  customdiv.appendChild(customcoldivforinput);
  addDropdown(customdiv,"idStringParameter" + (childNodeCount + 1));

  if (parameter != null && parameter != "" && parameter != undefined) {
    custominput.value = parameter;
  }

  custominput.required = true;

  customcoldiv.appendChild(customdiv);
  

  customrowdiv.appendChild(customcoldiv);

  customcoldiv = "";

  customcoldiv = document.createElement("DIV");
  customcoldiv.className = "col-1";
  customcoldiv.innerHTML = "<i class='fa fa-times' aria-hidden='true' onclick='RemoveTextbox(this.parentNode.parentNode)'></i>";
  customrowdiv.appendChild(customcoldiv);

  customcoldiv = "";

  var createplusicon = () => {
    customcoldiv = document.createElement("DIV");
    customcoldiv.className = "col-1";
    customcoldiv.innerHTML = "<i class='fa fa-plus' aria-hidden='true' onclick='AddTextbox(this)'></i>";
    customrowdiv.appendChild(customcoldiv);
  }

  if (index != null && index !== "" && index != undefined) {
    if (index == (length - 1)) {
      createplusicon();
    }
  } else {
    createplusicon();
  }
  document.getElementById("idConcatOperation").appendChild(customrowdiv);
  addVariableTodropDown();
}

function RemoveTextbox(ele) {

  var previousSibling = ele.previousSibling;
  var nextSibling = ele.nextSibling;
  if (nextSibling == null) {
    var customcoldiv = "";
    customcoldiv = document.createElement("DIV");
    customcoldiv.className = "col-1";
    customcoldiv.innerHTML = "<i class='fa fa-plus' aria-hidden='true' onclick='AddTextbox(this)'></i>";
    previousSibling.appendChild(customcoldiv);
  } else {
    var sibling = ele;
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== ele) {
        var parametercount = sibling.firstChild.firstChild.innerHTML.split(" ");
        var count = parseInt(parametercount[1]) - 1;
        sibling.firstChild.firstChild.innerHTML = "Parameter " + count.toString();
        sibling.firstChild.nextSibling.firstChild.id = "idStringParameter" + count.toString();
      }
      sibling = sibling.nextSibling;
    }
  }
  ele.remove();
}

function enableSourceXpathText(ele) {
  if (ele.checked) {
    document.getElementById("idSourceXpathText").style.display = "block";
  } else {
    document.getElementById("idSourceXpathText").style.display = "none";
  }
}

function createAttribute(attrvalue, attrname, id) {

  var customlist = document.createElement("li");
  var spantag=document.createElement("span");
  customlist.innerHTML="";
  spantag.innerHTML= attrname + "=" + "&quot" + attrvalue + "&quot";
  spantag.className="mappedxml";
  customlist.appendChild(spantag);
  var tag = "";
  tag = document.getElementById(id).getAttribute("tag");
  customlist.setAttribute("tag", tag + "/@" + attrname);
  _xmlNodeTreeCount++;
  customlist.id = _xmlNodeTreename + _xmlNodeTreeCount;
  var customul = document.createElement("ul");
  _xmlNodeTreeCount++;
  customul.id = _xmlNodeTreename + _xmlNodeTreeCount;
  var attrlistid = "";
  if ($("#" + id).find("ul")) {
    attrlistid = $("#" + id).find("ul")[0].id;

    var operation = $("#" + attrlistid).attr("operation");

    var childNodeLength = $("#" + attrlistid + " li").length;

    if (childNodeLength>=1) {
      var length=$("#" + attrlistid + " li").length;
      var listId = $("#" + attrlistid + " li")[length-1].id;
      $("#" + listId).removeAttr("isLastAttribute");
      customlist.setAttribute("isLastAttribute", true);
    }
    if (childNodeLength == 0) {
      $("#" + attrlistid).removeAttr("operation");
      $("#" + attrlistid).attr("operation", "attributes");
      customlist.setAttribute("isLastAttribute", true);
    }
    if (attrname.toLowerCase() == "_nodeidentifier" || attrname.toLowerCase() == "_commentidentifier") {
      customlist.style.display = "none";
      if(attrname.toLowerCase() == "_nodeidentifier")
      document.getElementById(id).setAttribute("_nodeidentifierListId", customlist.id);
    }

    if (operation == "childnodes") {
      $("#" + attrlistid).removeAttr("operation");
      $("#" + attrlistid).attr("operation", "childnodeswithattr");
    }
    if (attrname.toLowerCase().includes("_addcondition")) {
      document.getElementById(id).setAttribute("addedCondition", "true");
      document.getElementById(id).setAttribute("_addconditionid", customlist.id);
    } else if (attrname.toLowerCase().includes("_repeatablenode")) {


      //Creating a attribute for parent div by adding repeatablenode is true
      document.getElementById(id).setAttribute("repeatablenode", "true");
      //Creating a attribute for parent div by adding repeatable attribute id
      document.getElementById(id).setAttribute("repeatableid", customlist.id);
      //Adding repeatable flag for parent div
      document.getElementById(id).setAttribute("repeatableflag", "true");

    }

  }

  document.getElementById(attrlistid).appendChild(customlist);
  _xmlNodeTreeCount++;

}

function checkUlexistOrNot(attrid) {
  if ($("#" + attrid).find("ul")) {
    attrlistid = $("#" + attrid).find("ul")[0].id;
    var operation = $("#" + attrlistid).attr("operation");

    if (operation == "attributes") {
      $("#" + attrlistid).removeAttr("operation");
      $("#" + attrlistid).attr("operation", "childnodeswithattr");
    } else if (operation == "void") {
      $("#" + attrlistid).removeAttr("operation");
      $("#" + attrlistid).attr("operation", "childnodes");
    }
    return attrlistid;
  } else {
    return "";
  }
}

function CreateGuid() {

  function _p8(s) {
    var p = (Math.random().toString(16) + "000000000").substr(2, 8);
    return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
  }

  return _p8() + _p8(true) + _p8(true) + _p8();
}

function enableDivControlforAddCondition(ele) {
  if (ele.checked) {
    document.getElementById("idConditionExpression").style.display = "block";
    
  } else {
    document.getElementById("idConditionExpression").style.display = "none";
  }
}

function addSnippet(ele) {
  if(ele.checked==true){
    document.getElementById("idBtnSnippetDiv").style.display = "block";
    document.getElementById("idDivSnippet").style.display = "block";
    document.getElementById(attrid).setAttribute("addedSnippet", "true");
    document.getElementById("idSaveBtn").style.display = "none";
  }else{
    document.getElementById("idBtnSnippetDiv").style.display = "none";
    document.getElementById("idDivSnippet").style.display = "none";
    document.getElementById(attrid).removeAttribute("addedSnippet");
    document.getElementById("idSaveBtn").style.display = "block";
  }

}

function addNodeVariable(ele){
  if(ele.checked==true){
    document.getElementById("idGlobalVariable").style.display="block";
    document.getElementById(attrid).setAttribute("nodeVariable", "true");
  }else{
    document.getElementById("idGlobalVariable").style.display="none";
    document.getElementById(attrid).removeAttribute("nodeVariable", "true");
  }
}

function addSnippetOperation(ele) {
  var addSnippet = () => {
    var snippetvalue = document.getElementById("addSnippet").value;
    snippetvalue = "<Snippet>" + snippetvalue + "</Snippet>";
    var xmlDocument = (new DOMParser()).parseFromString(snippetvalue, 'text/xml');
    mappedxml = true;

    getxml(xmlDocument, mappedxml);

    document.getElementById(attrid).removeAttribute("nodeType");
    document.getElementById("addSnippet").value = "";
    document.getElementById("idattrInput").value = "";
    document.getElementById("idDivExpression").style.display = "none";
    document.getElementById("idDivSnippet").style.display = "none";
    document.getElementById("idSnippet").style.display = "none";
    document.getElementById("idSaveBtn").style.display = "block";
  }

  if (ele.name == "nextNode") {
    document.getElementById(attrid).setAttribute("nodeType", "nextNode");
    addSnippet();
  } else if (ele.name == "previousNode") {
    document.getElementById(attrid).setAttribute("nodeType", "previousNode");
    addSnippet();
  } else {
    document.getElementById(attrid).setAttribute("nodeType", "childNode");
    addSnippet();
  }

}

function DateTimeOperation() {
  var input = "";
  var currentinputformat = "";
  var expectedoutformat = "";
  var parametervalue = "";
  var isEmptyflag = false;
  clearMappedXmlOperation();
  document.getElementById("idDateFormat").style.display = "block";
  var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
  var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
  if (attrvalue.startsWith("_function") && (attrvalue.includes("GetConvertDateTime") || attrvalue.includes("GetConvertDateTimeRoundOff"))) {
    parametervalue = getResultParameterfromfunction(attrid);
    isEmptyflag = checkisemptyornot(parametervalue);
    if (!isEmptyflag || parametervalue.length > 0) {
      input = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
      currentinputformat = parametervalue[1].replace("'", "").replace("'", "");
      expectedoutformat = parametervalue[2].replace("'", "").replace("'", "").replace(")", "");
    }
  } else {
    input = document.getElementById(attrid).getAttribute("tag");
  }
  document.getElementById("idDateInput").value = input;
  document.getElementById("idDateCurrentInputFormat").value = currentinputformat;
  document.getElementById("idDateRequiredOutputFormat").value = expectedoutformat;
}

function CurrentDateTimeOperation() {

  var input = "";
  var parametervalue = "";
  var isEmptyflag = false;
  clearMappedXmlOperation();
  document.getElementById("idCurrentDateFormat").style.display = "block";
  var attrinnerHTML = document.getElementById(attrid).childNodes[0].innerHTML;
  var attrvalue = attrinnerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
  if (attrvalue.startsWith("_function") && (attrvalue.includes("GetCurrentDateTime") || attrvalue.includes("GetCurrentDateTimeRoundOff"))) {
    parametervalue = getResultParameterfromfunction(attrid);
    isEmptyflag = checkisemptyornot(parametervalue);
    if (!isEmptyflag || parametervalue.length > 0) {
      input = parametervalue[0].replace("'", "").replace("'", "").replace("(", "");
    }
  } else {
    input = document.getElementById(attrid).getAttribute("tag");
  }
  document.getElementById("idCurrentDateInput").value = input;
}

function AddGlobalVariable(list, ev) {

  ev.stopPropagation();
  clearMappedXmlOperation();
  document.getElementById("idDivExpression").style.display = "block";
  document.getElementById("idGlobalVariable").style.display = "block";
  if(list.innerHTML.toLowerCase()=="variable"){
    var nameAttrList=list.parentNode.getAttribute("variablenamelist");
    var valueAttrList=list.parentNode.getAttribute("variablevaluelist");
    var nameAttr=document.getElementById(nameAttrList).childNodes[0].innerHTML;
    var valueAttr=document.getElementById(valueAttrList).childNodes[0].innerHTML;
    var nameAttrValue=nameAttr.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
    var variableValue=valueAttr.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");

    document.getElementById("idGlobalVariableName").value=nameAttrValue;
    document.getElementById("idGlobalVariableValue").value=variableValue;

  }
  attrid = list.parentNode.id;
  document.getElementById(attrid).setAttribute("variable", "true");

}

function search(ele, xmlType) {
  var keyCode = (ele.keyCode ? ele.keyCode : ele.which);
  var xmlTreeData = "";
  var _searchKey = "";
  if (keyCode == 13) {
    if (xmlType == "mappedxml") {
      xmlTreeData = document.getElementById("idMappedXmlDiv").childNodes;
      _searchKey = document.getElementById("idMappedSearch").value;
    } else {
      xmlTreeData = document.getElementById("idTemplateXmlDiv").childNodes;
      _searchKey = document.getElementById("idTemplateSearch").value;
    }
    var isEmptyflag = false;
    isEmptyflag = checkisemptyornot(_searchKey);
    if (!isEmptyflag) {
      searchdata(xmlTreeData, _searchKey);
    }
  }
}

function searchdata(xmlTreeData, _searchKey) {

  var attrlistid = "";
  var _parentListId = "";
  for (var i = 0; i < xmlTreeData.length; i++) {

    if (xmlTreeData[i].className == "mappedxml" || xmlTreeData[i].className == "templatexml") {

      if (xmlTreeData[i].innerHTML.toLowerCase().indexOf(_searchKey.trim().toLowerCase()) > -1) {

        if ($("#" + xmlTreeData[i].parentNode.id).find("ul").length > 0) {

          attrlistid = $("#" + xmlTreeData[i].parentNode.id).find("ul")[0].id;

          _parentListId = xmlTreeData[i].parentNode.id;
          $("#" + _parentListId).children('span').addClass("caret-down");
          $("#" + attrlistid).addClass("active");

          if (xmlTreeData[i].parentNode.parentNode.id)
            checkForImmediateParent(xmlTreeData[i].parentNode.parentNode);
        } else {
          checkForImmediateParent(xmlTreeData[i].parentNode.parentNode);
        }
      }
    }
    if (xmlTreeData[i].childNodes.length > 0) {
      searchdata(xmlTreeData[i].childNodes, _searchKey);
    }
  }
}

function checkForImmediateParent(_parentNode) {

  var _parentNodeId = _parentNode.id;

  if (_parentNodeId) {

    var isActiveClass = false;

    isActiveClass = $("#" + _parentNodeId).hasClass("active");

    var tagName = $("#" + _parentNodeId)[0].tagName;
    if (!isActiveClass && tagName.toLowerCase() != "li") {

      $("#" + _parentNodeId).addClass("active");

    } else if (tagName.toLowerCase() == "li") {

      $("#" + _parentNodeId).children('span').addClass("caret-down");

    }
  }
  if (_parentNode.parentNode.id) {
    checkForImmediateParent(_parentNode.parentNode);
  }
}

function Expand() {
  var xmlTreeData = document.getElementById("idMappedXmlDiv").childNodes;
  expandAll(xmlTreeData);
}

function expandAll(xmlTreeData) {
  var tagName = "";
  var isEmptyflag = false;
  for (var i = 0; i < xmlTreeData.length; i++) {
    tagName = xmlTreeData[i].tagName;
    isEmptyflag = checkisemptyornot(tagName);
    if (!isEmptyflag) {
      var nodeId = xmlTreeData[i].id;
      if (tagName.toLowerCase() == "ul") {
        var isActiveClass = false;
        isActiveClass = $("#" + nodeId).hasClass("active");
        if (!isActiveClass) {
          $("#" + nodeId).addClass("active");
        }
      } else if (tagName.toLowerCase() == "li") {
        $("#" + nodeId).children('span').addClass("caret-down");
      }
    }
    if (xmlTreeData[i].childNodes.length > 0) {
      expandAll(xmlTreeData[i].childNodes);
    }
  }
}

function Collapse() {
  var xmlTreeData = document.getElementById("idMappedXmlDiv").childNodes;
  collapseAll(xmlTreeData);
}

function collapseAll(xmlTreeData) {
  var tagName = "";
  var isEmptyflag = false;
  for (var i = 0; i < xmlTreeData.length; i++) {
    tagName = xmlTreeData[i].tagName;
    isEmptyflag = checkisemptyornot(tagName);
    if (!isEmptyflag) {

      var nodeId = xmlTreeData[i].id;
      if (tagName.toLowerCase() == "ul") {

        var isActiveClass = false;
        isActiveClass = $("#" + nodeId).hasClass("active");
        if (isActiveClass) {
          $("#" + nodeId).removeClass("active");
        }
      } else if (tagName.toLowerCase() == "li") {
        $("#" + nodeId).children('span').removeClass("caret-down");
      }
    }
    if (xmlTreeData[i].childNodes.length > 0) {
      collapseAll(xmlTreeData[i].childNodes);
    }
  }
}

function disableCheckBox() {
  var addconditionCheckbox = document.getElementById("idcondition").checked;
  var repeatablenodeCheckbox = document.getElementById("idrepeatable").checked;
  var snippetCheckbox = document.getElementById("idSnippetCheck").checked;
  var nodeVariableCheckBox=document.getElementById("idVariableNodeCheck").checked;
  // var attributeCheckBox=document.getElementById("idaddAttribute").checked;

  if (addconditionCheckbox) {
    $("#idrepeatable").prop("disabled", true);
    $("#idSnippetCheck").prop("disabled", true);
    $("#idVariableNodeCheck").prop("disabled", true);
  } else if (repeatablenodeCheckbox) {
    $("#idcondition").prop("disabled", true);
    $("#idSnippetCheck").prop("disabled", true);
    $("#idVariableNodeCheck").prop("disabled", true);
  } else if (snippetCheckbox) {
    $("#idrepeatable").prop("disabled", true);
    $("#idcondition").prop("disabled", true);
    $("#idVariableNodeCheck").prop("disabled", true);
  }else if(nodeVariableCheckBox){
    $("#idrepeatable").prop("disabled", true);
    $("#idSnippetCheck").prop("disabled", true);
    $("#idcondition").prop("disabled", true);
  }
}

function removedDisableAttr() {
  $("#idrepeatable").removeAttr("disabled");
  $("#idSnippetCheck").removeAttr("disabled");
  $("#idcondition").removeAttr("disabled");
}

function addComment(){

  var commentNodeXpath=document.getElementById(commentullistid).parentNode.getAttribute("tag");
  var comment="";
  var listId="";
  document.getElementById(commentullistid).removeAttribute("operation");
  document.getElementById(commentullistid).setAttribute("operation","childnodes");
  var nameValue=document.getElementById(attrid).getAttribute("tag");
  var nameXpath=commentNodeXpath+"/Key/@name";
  var notesXpath=commentNodeXpath+"/Key/@Notes";
  comment=document.getElementById("textComment").value;
  var commentnodeexist=false;
  var checkcommentcontainsspaceornot="";
  commentnodeexist=checkCommentExistOrNot();
  checkcommentcontainsspaceornot=comment.replace(/\s/g, '');
  if(checkcommentcontainsspaceornot!="" && commentullistid != null && commentullistid != "" && commentullistid != undefined && !commentnodeexist){

      var customlist = document.createElement("Li");
      _xmlNodeTreeCount++;
      customlist.id = _xmlNodeTreename + _xmlNodeTreeCount;
      listId=_xmlNodeTreename + _xmlNodeTreeCount;
      customlist.setAttribute("tag", (commentNodeXpath.replace("mappingData/", "")+"/Key"));
      _xmlNodeTreeCount++;
      customlist.setAttribute("node", "true");
      var spantag=document.createElement("span");
      spantag.className="caret";
      spantag.setAttribute("onclick","toggleCarret(this,event)");
      customlist.appendChild(spantag);
      spantag="";
      spantag=document.createElement("span");
      spantag.className="mappedxml";
      spantag.innerHTML="Key";
      customlist.appendChild(spantag);
  
      document.getElementById(commentullistid).appendChild(customlist);
  
      var customul = document.createElement('ul');
      customul.setAttribute("operation", "attributes");
      customul.className = "nested";
      _xmlNodeTreeCount++;
      customul.id = _xmlNodeTreename + _xmlNodeTreeCount;
      _xmlNodeTreeCount++;
  
      document.getElementById(customlist.id).appendChild(customul);
      
      customlist = "";
  
      customlist = document.createElement("li");
      customlist.id = _xmlNodeTreename + _xmlNodeTreeCount;
      customlist.setAttribute("tag",nameXpath);
      _xmlNodeTreeCount++;
      customlist.innerHTML ="<span class='mappedxml'>name="+'"' +nameValue  + '"'+"</span> ";
      document.getElementById(customul.id).appendChild(customlist);
      customlist = "";
  
      customlist = document.createElement("li");
      customlist.id = _xmlNodeTreename + _xmlNodeTreeCount;
      customlist.setAttribute("tag",notesXpath);
      _xmlNodeTreeCount++;
      customlist.innerHTML ="<span   class='mappedxml'>Notes="+'"' + comment + '"'+"</span> ";
      document.getElementById(customul.id).appendChild(customlist);
      document.getElementById("textComment").value="";
      var node=document.getElementById(attrid).getAttribute("node");
      var nodeidentifier="";
      if(node=="true"){
        nodeidentifier=document.getElementById(attrid).getAttribute("nodeidentifier");
      }else{
        nodeidentifier=document.getElementById(attrid).parentNode.parentNode.getAttribute("nodeidentifier");
      }
      createAttribute(nodeidentifier,"_NodeIdentifier",listId);
  }
}

function checkCommentExistOrNot(){
  var attributexpath=document.getElementById(attrid).getAttribute("tag");
  var node=document.getElementById(attrid).getAttribute("node");
  var nodeidentifier="";
  if(node=="true"){
    nodeidentifier=document.getElementById(attrid).getAttribute("nodeidentifier");
  }else{
    nodeidentifier=document.getElementById(attrid).parentNode.parentNode.getAttribute("nodeidentifier");
  }
 
  const identifierlist=document.querySelectorAll('[tag="Comment/Key/@_NodeIdentifier"]');
  var comment="";
  var useraddedcomment="";
  useraddedcomment=document.getElementById("textComment").value;
  var identifierflag=false;
  var result=false;
  var commentattr="";
  var checkcommentcontainsspaceornot="";
  identifierlist.forEach(ele=>{
    var tagvalue="";
    tagvalue=ele.firstChild.innerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
    if(tagvalue==nodeidentifier){
      var atttrxpath=ele.parentNode.firstChild.firstChild.innerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      if(attributexpath==atttrxpath){
        identifierflag=true;
        commentattr=ele;
      }
      
    }
  });
  if(commentattr!=""){
    if(identifierflag)
    {
      comment=commentattr.previousSibling.firstChild.innerHTML.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, "");
      checkcommentcontainsspaceornot=useraddedcomment.replace(/\s/g, '');
      if((checkcommentcontainsspaceornot!="")  && (comment!=useraddedcomment)){
        var commentNotes= commentattr.previousSibling.firstChild.innerHTML;
        commentattr.previousSibling.firstChild.innerHTML=commentNotes.substring(0,commentNotes.indexOf("=")+1)+'"'+useraddedcomment+'"';
        document.getElementById("textComment").value=useraddedcomment;

      }
      else{
        document.getElementById("textComment").value=comment;
      }

      result=true;
     
    }
  }
 

  return result;
}

function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}

function ReplaceAll(attrvalue,xpath){
  var operation="";
  if(attrvalue!=""){
  var regExp = new RegExp('[^\(]*(\(.*\))[^\)]*', 'g');
  var stringoperarionparameter = "";
  var values="";
  var result="";
  var parametervalue;
  if(attrvalue.startsWith("_xsltfunction")||attrvalue.startsWith("_function")){
    operation=attrvalue.split(":")[1].substring(0,attrvalue.indexOf("(")-1);
    stringoperarionparameter=regExp.exec(attrvalue.split(":")[1]);
  }
  else{
    operation=attrvalue.substring(0,attrvalue.indexOf("(")-1);
    stringoperarionparameter=regExp.exec(attrvalue);
  }
  if(attrvalue.startsWith("_xsltfunction")){
    parametervalue = stringoperarionparameter[1].split('#');
  }
  else{
    var regExp = new RegExp("^.*(?:'.*')+.*$", 'g');
    parametervalue=regExp.exec(stringoperarionparameter[1]);
    parametervalue =parametervalue!=null?parametervalue.split(","):'';
  }
  if(parametervalue!=""){
    parametervalue.forEach(ele=>{
      if(ele!=""){
        ele=ele.replace("(","").replace(")","");
        if(ele.startsWith(".")){
          ele=ele.replace(".",xpath);
        }
      }
    });
  }
  
  if(operation!=""){
    if(attrvalue.startsWith("_xsltfunction")){
      values = parametervalue.join("#");
      result="_xsltfunction:"+operation+"("+values+")";
    }
    else if(attrvalue.startsWith("_function")){
      values = parametervalue.join(",");
      result="_function:"+operation+"("+values+")";
    }
    else{
      values = parametervalue.join(",");
      result=operation+"("+values+")";
    }
  }
}
  return result;
}

function ValidateXpath(){
  document.evaluate('',null,null,XPathResult.ANY_TYPE,null)
}


function addDropdown(custominputgroup,inputId){
  var coldiv=document.createElement('div');
  coldiv.className='col-sm-2 pl-0';
  var customdivinputappend=document.createElement("DIV");
   customdivinputappend.className='input-group-append';

   var dropdowndiv=document.createElement('div');
   dropdowndiv.className='dropdown';

   var custombtn=document.createElement('button');
   custombtn.className='btn btn-info dropdown-toggle';
   custombtn.type='button';
   custombtn.setAttribute("data-toggle","dropdown");
   
   var customul=document.createElement('ul');
   customul.className='dropdown-menu';
   customul.id='dropdownlistforconcat'+inputId;

  var customli=document.createElement('li');
  customli.setAttribute("onclick","enableXpath('"+inputId+"')");

  var customanchortag=document.createElement('a');
  customanchortag.tabIndex=-1;
  customanchortag.innerHTML='Xpath';

  customli.appendChild(customanchortag);
  customul.appendChild(customli);

  customli='';
  customanchortag='';

  customli=document.createElement('li');
  customli.className='dropdown-submenu data-toggle';

  customanchortag=document.createElement('a');
  customanchortag.className='dropdown-toggle';
  customanchortag.tabIndex=0;
  customanchortag.innerHTML='Variable';

  customli.appendChild(customanchortag);

  var customulforsubmenu=document.createElement('ul');
  customulforsubmenu.className='dropdown-menu idVariablesubMenu';
  customulforsubmenu.setAttribute("inputId",inputId);

  customli.appendChild(customulforsubmenu);

  customul.appendChild(customli);

  dropdowndiv.appendChild(custombtn);
  dropdowndiv.appendChild(customul);
  customdivinputappend.appendChild(dropdowndiv);
  coldiv.appendChild(customdivinputappend);

  custominputgroup.appendChild(coldiv);

}

function enableXpath(inputId){
  if(document.getElementById(inputId).getAttribute("addedVariable")){
    document.getElementById(inputId).removeAttribute("addedVariable");
  }
  document.getElementById(inputId).setAttribute('addedXpath',true);
  document.getElementById(inputId).readOnly=false;
}